import React, { Component } from 'react';
import styled from 'styled-components';
import CrossIcon from '../../images/common/cross.svg';
import DateIcon from '../../images/star-health-status/dateicon.svg';
import StarIcon from '../../images/star-health-status/g-star.png';
import lottie from 'lottie-web';

const ModalBoundary = styled.div`
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  color: #ffffff;
`;

const InnerBoundary = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CrossContainer = styled.div`
  padding: 24px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0px 0px;
  padding: 18px;
`;

const Card = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  height: 400px;
`;

const Rewards = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-center: space-between;
  align-items: center;
  padding: 6px 24px;
  border-radius: 24px;
  position: relative;
`;

const RewardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 20%;
  background: #ffffff;
  padding-left: 14px;
  margin-left: 6px;
  padding-right: 14px;
  border-radius: 16px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
`;

const RewardPoints = styled.p`
  background: linear-gradient(45deg, #ede67b, #a37520);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 28px;
`;

const CardHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: black;
  border-radius: 16px 16px 0px 0px;
  background: #ffffff;
`;

const CardFooter = styled.div`
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  background: linear-gradient(45deg, #ede67b, #a37520);
  padding-top: 10px;
  padding-bottom: 20px;
  border-radius: 0px 0px 16px 16px;
`;

export default class CongratulationModal extends Component {
  constructor(props) {
    super(props);

    this.giftBox = React.createRef(null);
    this.url =
      'https://visit-public.s3.ap-south-1.amazonaws.com/assets/Voila.mp3';
    this.audio = new Audio(this.url);
  }

  componentDidMount() {
    lottie.loadAnimation({
      container: this.giftBox.current,
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require('../wellness-rewards/gift-box.json'),
    });
    // console.log('The Sound', this.audio);
    this.audio.play();
    // if (window.newReward && window.audioContext) {
    //   const soundSource = window.audioContext.createBufferSource();
    //   soundSource.buffer = window.newReward;
    //   soundSource.connect(window.audioContext.destination);
    //   soundSource.start();
    // }
  }

  render() {
    const { rewardData } = this.props;
    return (
      <ModalBoundary>
        <CrossContainer>
          <img
            src={CrossIcon}
            style={{
              float: 'right',
              width: '24px',
              height: '24px',
              color: 'white',
            }}
            onClick={this.props.closeModal}
          />
        </CrossContainer>
        <HeaderContainer>
          <Title>Congratulations!</Title>
        </HeaderContainer>
        <Card>
          <CardHeader>
            <Rewards>
              <div>You just won</div>
              <div
                className="giftBox"
                ref={this.giftBox}
                style={{ width: '70%' }}
              ></div>
              <RewardContainer>
                <img
                  src={StarIcon}
                  style={{
                    paddingRight: '2px',
                    width: '24px',
                    height: '24px',
                  }}
                />
                <RewardPoints>{rewardData.rewardPoint}</RewardPoints>
              </RewardContainer>
            </Rewards>
          </CardHeader>
          <CardFooter>
            <div style={{ textAlign: 'center', width: '80%' }}>
              {rewardData.rewardName}
            </div>
            <div className="flex flex-row pt-24px">
              <img
                src={DateIcon}
                style={{ width: '24px', height: '24px', paddingRight: '6px' }}
              />
              <p>{rewardData.completedAt.toString().substring(0, 9)}</p>
            </div>
          </CardFooter>
        </Card>
      </ModalBoundary>
    );
  }
}
