import React, { useState, useEffect, useRef } from 'react';
import Video from 'twilio-video';
import styled from 'styled-components';
import videoCallClose from '../../images/consultations/video-call-close.svg';
import videoMute from '../../images/consultations/video-mute.svg';
import videoAudioMute from '../../images/consultations/video-audio-mute.svg';
import videoAudioUnmute from '../../images/consultations/unmute.svg';
import videoUnmute from '../../images/consultations/video-unmute.svg';
import videoBackIcon from '../../images/consultations/video-back-icon.svg';
import videoBackShapeIcon from '../../images/consultations/video-back-shape-icon.svg';
import redDot from '../../images/consultations/red-dot.svg';
import StyledTappable from '../common/StyledTappable';
import { Text } from '../common/Text';

const RemoteParticipants = styled.video`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;
const LocalParticipants = styled.video``;

const RemoteParticipantsImg = styled.img`
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
`;
const VideoBackIcon = styled.img`
  position: fixed;
  top: 55px;
  left: 32px;
`;
const VideoBackShapeIcon = styled.img`
  position: fixed;
  top: 69px;
  left: 46px;
`;
const BottomContent = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 0;
  background: rgba(26, 26, 26, 0.5);
  color: #f1f1f1;
  width: 90%;
  max-width: 327px;
  ${'' /* height: 138px; */}
  margin: 20px;
  border-radius: 8px;
`;
const BottomSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 12px;
  color: #f1f1f1;
`;
const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  margin-left: 5px;
  color: #f1f1f1;
`;
const HeadingText = styled(Text)`
  margin: 5px;
  font-size: 18px;
  color: #f1f1f1;
`;
const RegularText = styled(Text)`
  display: flex;
  flex-direction: row;
  margin-left: 5px;
  font-size: 16px;
  color: #f1f1f1;
  color: #f1f1f1;
`;
const DotIcon = styled.img`
  display: flex;
  margin-right: 5px;
`;
const ProfileImg = styled.img`
  flex: 1;
  display: flex;
  align-items: left;
  width: 60px;
  height: 60px;
`;
const ActionIcon = styled.img`
  max-height: 24px;
`;

const VideoCallCloseIcon = styled.img`
  height: 80px;
`;
const ActionContainer = styled(StyledTappable)`
  flex: 1;
  display: flex;
`;
const VideoRoom = ({
  roomName,
  token,
  userName,
  profileImg,
  history,
  handleFeedback,
  disableGoBack,
}) => {
  const [room, setRoom] = useState(null);
  const [participants, setParticipants] = useState(null);
  const [videoTracks, setVideoTracks] = useState([]);
  const [audioTracks, setAudioTracks] = useState([]);
  const [videoRemoteTracks, setVideoRemoteTracks] = useState([]);
  const [audioRemoteTracks, setAudioRemoteTracks] = useState([]);
  const [muteVideo, setMuteVideo] = useState(true);
  const [muteAudio, setMuteAudio] = useState(true);
  const [userConnected, setUserConnected] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);
  const [goBack, setGoBack] = useState(true);
  let mins = min;
  let secs = sec;

  const videoRef = useRef();
  const audioRef = useRef();
  const videoRemoteRef = useRef();
  const audioRemoteRef = useRef();
  let timeInterval;

  const trackpubsToTracks = (trackMap) =>
    Array.from(trackMap.values())
      .map((publication) => publication.track)
      .filter((track) => track !== null);
  const countUp = () => {
    console.log(mins, secs);
    if (!mins && !secs) {
      const timeInterval = setInterval(() => {
        if (secs >= 60) {
          mins = mins + 1;
          secs = 0;
          setMin(mins);
          setSec(0);
          console.log({ mins });
        } else {
          secs = secs + 1;
          setMin(mins);
          setSec(secs);
          console.log({ secs });
        }
      }, 1000);
      return timeInterval;
    }
  };
  // Connect Video call
  useEffect(() => {
    const participantConnected = (participant) => {
      setParticipants(participant);
      roomRemoteParticipants(participant);
      setUserConnected(1);
      timeInterval = countUp();
    };

    const participantDisconnected = () => {
      setParticipants(null);
    };

    Video.connect(token, {
      name: roomName,
    })
      .then((room) => {
        console.log(room);
        setRoom(room);
        room.participants.forEach(participantConnected);
        roomParticipants(room.localParticipant);
        room.on('participantConnected', participantConnected);
        room.on('participantDisconnected', participantDisconnected);
      })
      .catch((err) => {
        console.log(err);
      });

    return () => {
      setRoom((currentRoom) => {
        if (currentRoom && currentRoom.localParticipant.state === 'connected') {
          currentRoom.localParticipant.tracks.forEach(function (
            trackPublication
          ) {
            trackPublication.track.stop();
          });
          currentRoom.disconnect();
          if (timeInterval) {
            clearInterval(timeInterval);
          }
          return null;
        } else {
          return currentRoom;
        }
      });
    };
  }, [roomName, token]);
  // local participants
  const roomParticipants = (participant) => {
    setVideoTracks(trackpubsToTracks(participant.videoTracks));
    setAudioTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => [...videoTracks, track]);
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => [...audioTracks, track]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoTracks((videoTracks) => videoTracks.filter((v) => v !== track));
      } else if (track.kind === 'audio') {
        setAudioTracks((audioTracks) => audioTracks.filter((a) => a !== track));
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoTracks([]);
      setAudioTracks([]);
      participant.removeAllListeners();
    };
  };

  useEffect(() => {
    const videoTrack = videoTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoTracks]);

  useEffect(() => {
    const audioTrack = audioTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioTracks]);
  // Remote Participants
  const roomRemoteParticipants = (participant) => {
    console.log(participant);
    setVideoRemoteTracks(trackpubsToTracks(participant.videoTracks));
    setAudioRemoteTracks(trackpubsToTracks(participant.audioTracks));

    const trackSubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoRemoteTracks((videoRemoteTracks) => [
          ...videoRemoteTracks,
          track,
        ]);
      } else if (track.kind === 'audio') {
        setAudioRemoteTracks((audioRemoteTracks) => [
          ...audioRemoteTracks,
          track,
        ]);
      }
    };

    const trackUnsubscribed = (track) => {
      if (track.kind === 'video') {
        setVideoRemoteTracks((videoRemoteTracks) =>
          videoRemoteTracks.filter((v) => v !== track)
        );
      } else if (track.kind === 'audio') {
        setAudioRemoteTracks((audioRemoteTracks) =>
          audioRemoteTracks.filter((a) => a !== track)
        );
      }
    };

    participant.on('trackSubscribed', trackSubscribed);
    participant.on('trackUnsubscribed', trackUnsubscribed);

    return () => {
      setVideoRemoteTracks([]);
      setAudioRemoteTracks([]);
      participant.removeAllListeners();
    };
  };
  useEffect(() => {
    const videoTrack = videoRemoteTracks[0];
    if (videoTrack) {
      videoTrack.attach(videoRemoteRef.current);
      return () => {
        videoTrack.detach();
      };
    }
  }, [videoRemoteTracks]);

  useEffect(() => {
    const audioTrack = audioRemoteTracks[0];
    if (audioTrack) {
      audioTrack.attach(audioRemoteRef.current);
      return () => {
        audioTrack.detach();
      };
    }
  }, [audioRemoteTracks]);
  // Handle video call
  const handleMuteVideo = () => {
    if (room) {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
        setMuteVideo(false);
      });
    }
  };
  const handleMuteAudio = () => {
    if (room) {
      room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
        setMuteAudio(false);
      });
    }
  };
  const handleUnmuteVideo = () => {
    // console.log({ room }, 'sasasa');
    if (room) {
      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
        setMuteVideo(true);
      });
    }
  };
  const handleUnmuteAudio = () => {
    if (room) {
      room.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
        setMuteAudio(true);
      });
    }
  };
  const handleDisconnectRoom = () => {
    console.log(disableGoBack);
    if (room) {
      console.log('room');
      room.on('disconnected', (room) => {
        room.localParticipant.tracks.forEach((publication) => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach((element) => element.remove());
        });
      });
      room.disconnect();
      if (disableGoBack) {
        window.open('about:blank', '_self');
        window.close();
      } else {
        history.goBack();
      }
      if (timeInterval) {
        clearInterval(timeInterval);
      }
      handleFeedback();
    } else if (disableGoBack) {
      console.log('no');
      window.open('about:blank', '_self');
      window.close();
    } else {
      history.goBack();
    }
  };
  return (
    <div className="room">
      <div>
        {participants ? (
          <RemoteParticipants
            ref={videoRemoteRef}
            autoPlay={false}
            muted={true}
          />
        ) : (
          <RemoteParticipantsImg src={profileImg} />
        )}
      </div>
      <div>
        {room ? (
          <LocalParticipants
            className="video-local-participant"
            ref={videoRef}
            autoPlay={true}
          />
        ) : (
          <ProfileImg src={profileImg} />
        )}
      </div>
      <ActionContainer onTap={handleDisconnectRoom}>
        <VideoBackIcon src={videoBackIcon} />
        <VideoBackShapeIcon src={videoBackShapeIcon} />
      </ActionContainer>
      <BottomContent>
        <BottomSubContainer>
          <ProfileImg src={profileImg} />
          <SubContainer>
            <HeadingText>{userName}</HeadingText>
            {!userConnected ? (
              <RegularText>Connecting...</RegularText>
            ) : (
              <RegularText>
                <DotIcon src={redDot}></DotIcon>
                {(min !== null || min !== undefined) &&
                min.toString().length === 1
                  ? `0${min}`
                  : min}
                :
                {(sec !== null || sec !== undefined) &&
                sec.toString().length === 1
                  ? `0${sec}`
                  : sec}
              </RegularText>
            )}
          </SubContainer>
          <ActionContainer onTap={handleDisconnectRoom}>
            <VideoCallCloseIcon src={videoCallClose} />
          </ActionContainer>
        </BottomSubContainer>
        <BottomSubContainer>
          {muteVideo ? (
            <ActionContainer onTap={handleMuteVideo}>
              <ActionIcon
                style={{ height: '15px', width: '25px' }}
                src={videoUnmute}
              />
            </ActionContainer>
          ) : (
            <ActionContainer onTap={handleUnmuteVideo}>
              <ActionIcon src={videoMute} />
            </ActionContainer>
          )}
          {muteAudio ? (
            <ActionContainer onTap={handleMuteAudio}>
              <ActionIcon src={videoAudioMute} />
            </ActionContainer>
          ) : (
            <ActionContainer onTap={handleUnmuteAudio}>
              <ActionIcon src={videoAudioUnmute} />
            </ActionContainer>
          )}
        </BottomSubContainer>
      </BottomContent>
    </div>
  );
};

export default VideoRoom;
