import React, { Component } from 'react';
import styled from 'styled-components';
import { Transition } from 'react-transition-group';

const transitionDuration = 500;

const OuterContainer = styled.div`
  position: relative;
  align-self: stretch;
  background: #ededed;
  height: 4px;
  border: 1px solid #f0f3f6;
  border-radius: 6px;
`;

const ProgressContainer = styled.div.attrs({
  style: (props) => ({
    width: props.width || '1%',
  }),
})`
  transition: ${() => `width ${transitionDuration}ms ease-in-out`};
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 4px;
  background: #013ade;
  border-radius: 6px;
`;

const ProgressTracker = styled.div.attrs({
  style: (props) => ({
    left: `${String(Number(props.width.split('%')[0]) - 5) + '%'}` || '1%',
  }),
})`
  transition: ${() => `left ${transitionDuration}ms ease-in-out`};
  position: absolute;
  top: -36px;
  width: 32px;
  height: 20px;
  background: #f0f3f6;
  border-radius: 4px;
  font-weight: 600;
  font-size: 12px;
  color: #21243c;
  text-align: center;
  padding-top: 2px;
`;

const ProgressTrackerArrow = styled.div.attrs({
  style: (props) => ({
    left: `${String(Number(props.width.split('%')[0]) - 2) + '%'}` || '1%',
  }),
})`
  transition: ${() => `left ${transitionDuration}ms ease-in-out`};
  position: absolute;
  top: -12px;
  width: 0;
  height: 0px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 8px solid #f0f3f6;
  border-radius: 4px;
`;
export default class WaterProgressBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      transition: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ transition: true });
    }, 500);
    console.log(this.props.width);
  }

  render() {
    const { width, height, className, gradient, left } = this.props;
    const { transition } = this.state;
    const transitionWidth = {
      entering: '1%',
      entered: width || '1%',
      exiting: '0%',
      exited: '0%',
    };

    return (
      <OuterContainer className={className} height={height}>
        <Transition in={transition} timeout={transitionDuration}>
          {(state) => {
            return (
              <>
                <ProgressTracker width={transitionWidth[state]}>
                  {width}
                </ProgressTracker>
                <ProgressTrackerArrow width={transitionWidth[state]} />
                <ProgressContainer
                  width={transitionWidth[state]}
                  height={height}
                  left={gradient && gradient[0]}
                  right={gradient && gradient[1]}
                />
              </>
            );
          }}
        </Transition>
      </OuterContainer>
    );
  }
}
