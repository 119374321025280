import React, { Component } from 'react';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft } from 'react-icons/fa';
import {
  FadeInTransition,
  FlyInTransition,
} from '../../components/transitions/transitions';
import LoadingComponent from '../../components/common/LoadingComponent';
import { Text } from '../../components/common/Text';
import StepNon from '../../images/home/Stepss.svg';
import StepActive from '../../images/home/StepSvg.svg';
import DistanceWalkedIcon from '../../images/home/distancewalked.svg';
import DistanceWalkedIconActive from '../../images/home/distancewalked-active.svg';
import CaloriesActive from '../../images/home/CaloriesActive.svg';
import CaloriesNon from '../../images/home/CaloriesNon.svg';
import SleepNon from '../../images/home/SleepNon.svg';
import SleepActive from '../../images/home/SleepActive.svg';
import MealActive from '../../images/home/MealActive.svg';
import MealNon from '../../images/home/MealNon.svg';
import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';
import axios from 'axios';
import config from '../../config';
import { connect } from 'react-redux';
import Modal from '../common/Modal';
import DetailedGraphs from './DetailedGraph';
import * as d3 from 'd3';
import MealLogContainer from '../meal-log/MealLogContainer';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  font-family: Manrope;
  font-style: normal;
  height: 100%;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem;
  z-index: 10;
  user-select: none;
`;

const BackContainer = styled(Tappable)`
  margin-right: 12px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
  color: #ffffff;
  font-weight: 700px;
`;

const BodyContainer = styled.div`
  display: flex;
  height: 100%;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 16px;
`;

const IconsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: -1px;
  user-select: none;
`;

const IconContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #5a32e2;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  cursor: pointer;
  opacity: ${(props) => (props.active ? '100%' : '70%')};
`;

const Icon = styled.img`
  width: 60px;
  height: 60px;
  padding: 12px;
  border-radius: 50%;
  object-fit: contain;
  cursor: pointer;
`;

const SelectedItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  text-align: center;
  color: #ffffff;
  margin-top: -1px;
`;

const DatesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -1px;
  padding-left: 44px;
  padding-right: 44px;
  padding-bottom: 3.75px;
  padding-top: 16px;
`;

const Dates = styled.div`
  width: 50%;
  color: #21243c;
  cursor: pointer;
  text-align: center;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
`;

const VisibleDateContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  padding: 24px;
  margin-top: -1px;
`;

const RightArrowIcon = styled(FaAngleRight)`
  color: #21243c;
  font-size: 26px;
  cursor: pointer;
`;

const LeftArrowIcon = styled(FaAngleLeft)`
  color: #21243c;
  font-size: 26px;
  cursor: pointer;
`;

const VisibleDate = styled.div`
  color: #21243c;
  font-family: Manrope;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  user-select: none;
`;

const LoadingModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.6);
`;

export class HealthDataTracker extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      selectedItem: 'Step Count',
      selectedItemType: 'steps',
      selectedDateCondition: 'Daily',
      selectedDateType: 'day',
      date: new Date(),
      weeklyStartDate: new Date(),
      weeklyEndDate: new Date(),
      month: new Date(),
      modalLoading: false,
      data: null,
      currentDate: new Date(),
      showRightArrow: false,
      stepsGoal: 0,
      sleepGoal: 0,
      sleepGoalMin: null,
      distanceObj: {
        distanceGoal: 0,
        distanceCovered: 0,
        stepsPerKm: 0,
      },
    };
    const { location } = this.props;
    if (location && location.state) {
      const obj = location.state;
      if (
        obj.selectedItem === 'Meal Coverage' &&
        obj.from &&
        obj.from === 'MealLog'
      ) {
        this.state.selectedItem = obj.selectedItem;
      } else {
        const distanceGoal = (
          obj.distanceObj.distanceGoal / obj.distanceObj.stepsPerKm
        ).toFixed(2);
        const distanceCovered = 0;
        this.state.distanceObj = {
          distanceGoal: distanceGoal,
          distanceCovered: distanceCovered,
          stepsPerKm: obj.distanceObj.stepsPerKm,
        };
        this.state.stepsGoal = obj.stepsGoal;
        this.state.sleepGoal = obj.sleepGoal;
        this.state.sleepGoalMin = obj.sleepGoalMin;
        if (obj.selectedItem === 'Sleep Hours') {
          this.state.selectedItem = obj.selectedItem;
          this.state.selectedItemType = 'sleep';
        } else {
          this.state.selectedItem = obj.selectedItem;
        }
      }
    }
  }

  componentDidMount() {
    this.generateWeeklyDates(new Date());
    this.checkDates(this.state.date);
    this.generateData(this.state.date);
    // window.DetailedGraph();
  }

  componentDidUpdate() {
    console.log(this.state.distanceObj);
  }

  getDataForGeneratingGraph = (type, frequency, timestamp) => {
    if (window.Android !== undefined && window.Android.getDataToGenerateGraph) {
      window.Android.getDataToGenerateGraph(
        String(type),
        String(frequency),
        Number(timestamp)
      );
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'getDataToGenerateGraph',
          type: type,
          frequency: frequency,
          timestamp: timestamp,
        })
      );
    }
  };

  checkDates = (latestDate) => {
    let p = this.state.currentDate;
    const c = latestDate;
    if (c >= p) {
      this.setState({ showRightArrow: false });
    } else {
      this.setState({ showRightArrow: true });
    }
  };

  checkWeeklyDates = (startDate, endDate) => {
    if (
      this.state.currentDate >= startDate &&
      this.state.currentDate <= endDate
    ) {
      this.setState({
        showRightArrow: false,
      });
    } else {
      this.setState({
        showRightArrow: true,
      });
    }
  };

  generateWeeklyDates = (date) => {
    let curr = date;
    let first = curr.getDate() - ((curr.getDay() + 6) % 7);
    let last = first + 6;
    let firstday = new Date(curr.setDate(first));
    let lastday = new Date(curr.setDate(last));
    this.setState({
      weeklyStartDate: firstday,
      weeklyEndDate: lastday,
    });
    this.checkWeeklyDates(firstday, lastday);
  };

  decreaseDate = () => {
    let presentDate = this.state.date;
    let decreasedDate = new Date(presentDate);
    decreasedDate.setDate(decreasedDate.getDate() - 1);
    this.setState({ date: decreasedDate });
    this.generateData(decreasedDate);
    this.checkDates(decreasedDate);
  };

  increaseDate = () => {
    let presentDate = this.state.date;
    let currentDate = new Date();
    const p_day = presentDate.getDate();
    const p_month = presentDate.getMonth();
    const p_year = presentDate.getFullYear();
    const p = p_day + '-' + p_month + '-' + p_year;
    const c_day = currentDate.getDate();
    const c_month = currentDate.getMonth();
    const c_year = currentDate.getFullYear();
    const c = c_day + '-' + c_month + '-' + c_year;
    let increasedDate = new Date(presentDate);
    increasedDate.setDate(increasedDate.getDate() + 1);
    this.setState({ date: increasedDate });
    this.generateData(increasedDate);
    this.checkDates(increasedDate);
  };

  increaseWeekly = () => {
    let presentStartDate = this.state.weeklyStartDate;
    let presentEndDate = this.state.weeklyEndDate;
    let increasedStartDate = new Date(presentStartDate);
    let increasedEndDate = new Date(presentEndDate);
    increasedStartDate.setDate(increasedStartDate.getDate() + 7);
    increasedEndDate.setDate(increasedEndDate.getDate() + 7);
    this.setState({
      weeklyStartDate: increasedStartDate,
      weeklyEndDate: increasedEndDate,
    });
    this.generateData(increasedStartDate);
    this.checkWeeklyDates(increasedStartDate, increasedEndDate);
  };

  decreaseWeekly = () => {
    let presentStartDate = this.state.weeklyStartDate;
    let presentEndDate = this.state.weeklyEndDate;
    let decreasedStartDate = new Date(presentStartDate);
    let decreasedEndDate = new Date(presentEndDate);
    decreasedStartDate.setDate(decreasedStartDate.getDate() - 7);
    decreasedEndDate.setDate(decreasedEndDate.getDate() - 7);
    this.setState({
      weeklyStartDate: decreasedStartDate,
      weeklyEndDate: decreasedEndDate,
    });
    this.generateData(decreasedStartDate);
    this.checkWeeklyDates(decreasedStartDate, decreasedEndDate);
  };

  increaseMonthly = () => {
    let currentMonth = this.state.month.getMonth();
    let nextMonth = new Date(this.state.month.setMonth(currentMonth + 1));
    this.setState({ month: nextMonth });
    this.generateData(nextMonth);
    this.checkDates(nextMonth);
  };

  decreaseMonthly = () => {
    let currentMonth = this.state.month.getMonth();
    let previousMonth = new Date(this.state.month.setMonth(currentMonth - 1));
    this.setState({ month: previousMonth });
    this.generateData(previousMonth);
    this.checkDates(previousMonth);
  };

  generateData = (date) => {
    // this.setState({ modalLoading: true });
    const type = this.state.selectedItemType;
    const frequency = this.state.selectedDateType;
    let timestamp;
    if (frequency === 'day') {
      timestamp = date;
    } else if (frequency === 'week') {
      timestamp = date;
    } else if (frequency === 'month') {
      let firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      timestamp = firstDay;
    }
    console.log(type, frequency, timestamp);
    this.getDataForGeneratingGraph(type, frequency, timestamp);
  };

  // generateSleepData = async (date) => {
  //   const time = date.valueOf();
  //   console.log(time);
  //   const type = this.state.selectedDateType;
  //   const userId = this.props.userId;
  //   await axios
  //     .get(
  //       `${config.apiBaseUrl}/fitness/views/detailed-activity?t=sleep&f=${type}&st=${time}&userId=${userId}`
  //     )
  //     .then((response) => {
  //       console.log(response.data);
  //       this.setState({ modalLoading: false, data: response.data });
  //     })
  //     .catch((err) => {
  //       this.setState({ modalLoading: false });
  //       console.log(err);
  //     });
  // };

  getBgColor = (type) => {
    switch (type) {
      case 'Meal Coverage':
        return '#06c2a9';
      case 'Sleep Hours':
        return '#3A2CA0';
      default:
        return '#4D75E7';
    }
  };

  getBgColorForIcon = (type) => {
    switch (type) {
      case 'Meal Coverage':
        return '#99E6DC';
      case 'Sleep Hours':
        return '#6d59ff';
      default:
        return '#013ADE';
    }
  };

  render() {
    return (
      <div className="overflow-auto w-full">
        {this.state.loading ? (
          <div className="p-20 center">
            <LoadingComponent />
          </div>
        ) : (
          <OuterContainer>
            <div
              className="background-transition"
              style={{
                background: this.getBgColor(this.state.selectedItem),
              }}
            >
              <HeaderContainer>
                <BackContainer onTap={() => this.props.history.push('/home')}>
                  <StyledArrow />
                </BackContainer>
                <HeaderTitleContainer>
                  <p className="font-semibold text-title-m text-textbox">
                    Your Health Data
                  </p>
                </HeaderTitleContainer>
              </HeaderContainer>
              <IconsList>
                <IconContainer
                  style={{
                    backgroundColor:
                      this.state.selectedItem === 'Step Count'
                        ? '#ffffff'
                        : this.getBgColorForIcon(this.state.selectedItem),
                  }}
                  active={this.state.selectedItem === 'Step Count'}
                >
                  <Icon
                    src={
                      this.state.selectedItem === 'Step Count'
                        ? StepActive
                        : StepNon
                    }
                    alt="STEPS"
                    onClick={() => {
                      this.setState(
                        {
                          selectedItem: 'Step Count',
                          selectedItemType: 'steps',
                          selectedDateCondition: 'Daily',
                          selectedDateType: 'day',
                        },
                        () => {
                          this.generateData(this.state.date);
                          this.checkDates(this.state.date);
                        }
                      );
                    }}
                  />
                </IconContainer>
                <IconContainer
                  style={{
                    backgroundColor:
                      this.state.selectedItem === 'Distance Walked'
                        ? '#ffffff'
                        : this.getBgColorForIcon(this.state.selectedItem),
                  }}
                  active={this.state.selectedItem === 'Distance Walked'}
                >
                  <Icon
                    src={
                      this.state.selectedItem === 'Distance Walked'
                        ? DistanceWalkedIconActive
                        : DistanceWalkedIcon
                    }
                    style={{ tintColor: '#ffffff' }}
                    alt="DISTANCE"
                    onClick={() => {
                      this.setState(
                        {
                          selectedItem: 'Distance Walked',
                          selectedItemType: 'distance',
                          selectedDateCondition: 'Daily',
                          selectedDateType: 'day',
                        },
                        () => {
                          this.generateData(this.state.date);
                          this.checkDates(this.state.date);
                        }
                      );
                    }}
                  />
                </IconContainer>
                <IconContainer
                  style={{
                    backgroundColor:
                      this.state.selectedItem === 'Calories Burnt'
                        ? '#ffffff'
                        : this.getBgColorForIcon(this.state.selectedItem),
                  }}
                  active={this.state.selectedItem === 'Calories Burnt'}
                >
                  <Icon
                    src={
                      this.state.selectedItem === 'Calories Burnt'
                        ? CaloriesActive
                        : CaloriesNon
                    }
                    alt="CALORIES"
                    onClick={() => {
                      this.setState(
                        {
                          selectedItem: 'Calories Burnt',
                          selectedItemType: 'calories',
                          selectedDateCondition: 'Daily',
                          selectedDateType: 'day',
                        },
                        () => {
                          this.generateData(this.state.date);
                          this.checkDates(this.state.date);
                        }
                      );
                    }}
                  />
                </IconContainer>
                <IconContainer
                  style={{
                    backgroundColor:
                      this.state.selectedItem === 'Sleep Hours'
                        ? '#ffffff'
                        : this.getBgColorForIcon(this.state.selectedItem),
                  }}
                  active={this.state.selectedItem === 'Sleep Hours'}
                >
                  <Icon
                    src={
                      this.state.selectedItem === 'Sleep Hours'
                        ? SleepActive
                        : SleepNon
                    }
                    alt="SLEEP"
                    onClick={() => {
                      this.setState(
                        {
                          selectedItem: 'Sleep Hours',
                          selectedItemType: 'sleep',
                          selectedDateCondition: 'Daily',
                          selectedDateType: 'day',
                        },
                        () => {
                          this.generateData(this.state.date);
                          this.checkDates(this.state.date);
                        }
                      );
                    }}
                  />
                </IconContainer>
                <IconContainer
                  style={{
                    backgroundColor:
                      this.state.selectedItem === 'Meal Coverage'
                        ? '#ffffff'
                        : this.getBgColorForIcon(this.state.selectedItem),
                  }}
                  active={this.state.selectedItem === 'Meal Coverage'}
                >
                  <Icon
                    src={
                      this.state.selectedItem === 'Meal Coverage'
                        ? MealActive
                        : MealNon
                    }
                    alt="FOOD"
                    onClick={() => {
                      // this.props.history.push('/logmeal');
                      this.setState({ selectedItem: 'Meal Coverage' });
                    }}
                  />
                </IconContainer>
              </IconsList>
              <SelectedItem
                style={
                  this.state.selectedItem === 'Meal Coverage'
                    ? { paddingBottom: '24px' }
                    : { paddingBottom: '0px' }
                }
              >
                {this.state.selectedItem}
              </SelectedItem>
              {this.state.selectedItem != 'Meal Coverage' && (
                <>
                  <DatesContainer>
                    <Dates
                      style={
                        this.state.selectedDateCondition === 'Daily'
                          ? {
                              color: '#ffffff',
                              borderBottom: '3px solid white',
                            }
                          : {
                              color: '#C0C0C0',
                            }
                      }
                      onClick={() => {
                        this.setState(
                          {
                            selectedDateCondition: 'Daily',
                            selectedDateType: 'day',
                          },
                          () => {
                            this.generateData(this.state.date);
                            this.checkDates(this.state.date);
                          }
                        );
                      }}
                    >
                      Daily
                    </Dates>
                    <Dates
                      style={
                        this.state.selectedDateCondition === 'Weekly'
                          ? {
                              color: '#ffffff',
                              borderBottom: '3px solid white',
                            }
                          : {
                              color: '#C0C0C0',
                            }
                      }
                      onClick={() => {
                        this.setState(
                          {
                            selectedDateCondition: 'Weekly',
                            selectedDateType: 'week',
                          },
                          () => {
                            this.generateData(this.state.weeklyStartDate);
                            this.checkWeeklyDates(
                              this.state.weeklyStartDate,
                              this.state.weeklyEndDate
                            );
                          }
                        );
                      }}
                    >
                      Weekly
                    </Dates>
                    {this.state.selectedItemType != 'sleep' && (
                      <Dates
                        style={
                          this.state.selectedDateCondition === 'Monthly'
                            ? {
                                color: '#ffffff',
                                borderBottom: '3px solid white',
                              }
                            : {
                                color: '#C0C0C0',
                              }
                        }
                        onClick={() => {
                          this.setState(
                            {
                              selectedDateCondition: 'Monthly',
                              selectedDateType: 'month',
                            },
                            () => {
                              this.generateData(this.state.month);
                              this.checkDates(this.state.month);
                            }
                          );
                        }}
                      >
                        Monthly
                      </Dates>
                    )}
                  </DatesContainer>
                  <VisibleDateContainer>
                    {this.state.selectedDateCondition === 'Daily' && (
                      <>
                        <LeftArrowIcon onClick={this.decreaseDate} />
                        <VisibleDate>
                          {this.state.date.getDate() +
                            '   ' +
                            this.state.date.toLocaleString('default', {
                              month: 'long',
                            })}
                        </VisibleDate>
                        {this.state.showRightArrow ? (
                          <RightArrowIcon onClick={this.increaseDate} />
                        ) : (
                          <div style={{ visibility: 'hidden' }}>
                            <RightArrowIcon />
                          </div>
                        )}
                      </>
                    )}

                    {this.state.selectedDateCondition === 'Weekly' && (
                      <>
                        <LeftArrowIcon onClick={this.decreaseWeekly} />
                        <VisibleDate>
                          {this.state.weeklyStartDate.getDate() +
                            '   ' +
                            this.state.weeklyStartDate.toLocaleString(
                              'default',
                              {
                                month: 'long',
                              }
                            ) +
                            ' - ' +
                            this.state.weeklyEndDate.getDate() +
                            '   ' +
                            this.state.weeklyEndDate.toLocaleString('default', {
                              month: 'long',
                            })}
                        </VisibleDate>
                        {this.state.showRightArrow ? (
                          <RightArrowIcon onClick={this.increaseWeekly} />
                        ) : (
                          <div style={{ visibility: 'hidden' }}>
                            <RightArrowIcon />
                          </div>
                        )}
                      </>
                    )}

                    {this.state.selectedDateCondition === 'Monthly' && (
                      <>
                        <LeftArrowIcon onClick={this.decreaseMonthly} />
                        <VisibleDate>
                          {this.state.month.toLocaleString('default', {
                            month: 'long',
                          })}
                        </VisibleDate>
                        {this.state.showRightArrow ? (
                          <RightArrowIcon onClick={this.increaseMonthly} />
                        ) : (
                          <div style={{ visibility: 'hidden' }}>
                            <RightArrowIcon />
                          </div>
                        )}
                      </>
                    )}
                  </VisibleDateContainer>
                </>
              )}
            </div>
            <BodyContainer
              className={
                this.state.selectedItem === 'Meal Coverage' ? 'p-0' : ''
              }
            >
              {this.state.selectedItem === 'Meal Coverage' ? (
                <div
                  className="w-full"
                  style={{ width: '100%', height: '100%' }}
                >
                  <MealLogContainer
                    history={this.props.history}
                    removeHeader={true}
                  />
                </div>
              ) : (
                <DetailedGraphs
                  type={this.state.selectedItemType}
                  frequency={this.state.selectedDateType}
                  stepsGoal={this.state.stepsGoal}
                  sleepGoal={this.state.sleepGoal}
                  distanceObj={this.state.distanceObj}
                  sleepGoalMin={this.state.sleepGoalMin}
                />
              )}
            </BodyContainer>
            {this.state.modalLoading && (
              <Modal>
                <LoadingModalContainer>
                  <div className="p-20 center">
                    <LoadingComponent />
                  </div>
                </LoadingModalContainer>
              </Modal>
            )}
          </OuterContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userId: state.user.userId,
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(HealthDataTracker);
