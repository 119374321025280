/* eslint-disable jsx-a11y/anchor-has-content */
import React, { Component } from 'react';
import styled from 'styled-components';

import Header from '../../components/common/Header';

import tickIcon from '../../images/lab-test/tick.png';
import pdfIcon from '../../images/lab-test/pdf-icon.svg';
import { connect } from 'react-redux';
import Tappable from 'react-tappable/lib/Tappable';
import {
  fetchClaimSummary,
  fetchClaimSummaryV2,
  uploadDocuments,
} from '../../services';
import StyledTappable from '../../components/common/StyledTappable';
import { FlyInTransition } from '../../components/transitions/transitions';
import Modal from '../common/Modal';
import photoIcon from '../../images/lab-test/photo-icon.svg';
import fileIcon from '../../images/lab-test/file-icon.svg';
import MessageModal from '../../components/pharmacy/MessageModal';
import { logEvent } from '../../utils/logEvent';
import LoadingComponent from '../../components/common/LoadingComponent';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  overflow-y: hidden;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding: 18px 14px;
  background: #fff;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1rem;
`;

const StepContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-left: 1px solid;
  border-left-style: dashed;
  opacity: ${(props) => (props.inactive ? 0.3 : 1)};
`;

const AttachedFilesContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  flex-wrap: wrap;
`;

const AttachFileContainer = styled(Tappable)`
  position: relative;
  flex-shrink: 0;
  margin: 0 0.5rem;
  background-image: ${() => `url(${pdfIcon})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2rem;
  width: 45px;
  height: 64px;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
`;

const AttachImageContainer = styled(Tappable)`
  position: relative;
  flex-shrink: 0;
  margin: 0 0.5rem;
  background-image: ${(props) => `url(${props.fileUrl})`};
  background-repeat: no-repeat;
  background-size: cover;
  width: 45px;
  height: 64px;
  margin-top: 0.25rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  box-shadow: 0px 0px 1px 0px black;
`;

const StatusIcon = styled.div`
  border-radius: 50%;
  margin-left: -7px;
  background-color: ${(props) =>
    props.active || props.completed ? '#6544C6' : '#ffffff'};
  border: 1px solid #6544c6;
  width: 16px;
  height: 14px;
`;

const StatusIconImg = styled.img`
  background: #6544c6;
  margin-left: -7px;
  border-radius: 50%;
  padding: 4px;
`;

const StepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: ${(props) =>
    props.active || props.completed ? 'rgb(79, 52, 210,0.05)' : ''};
  border-radius: 8px;
  padding: 10px 15px;
  margin: 9px;
  width: 100%;
`;

const StatusContainer2 = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  background: #ffffff;
  border: 1px solid ${(props) => props.borderColor || '#916EF7'};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
`;
const DateText = styled.p`
  margin-right 3px;
  margin-top: 8px;
  margin-bottom: 16px;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  text-align: right;
  color: #767787;
`;

const StatusUploadSubContainer = styled.div`
  padding: 12px;
  margin-top: 15px;
  background: #f0f3f6;
  opacity: 0.8;
  border-radius: 12px;
`;

const StatusDetail = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 9px;
  border-bottom-color: ${(props) => (props.bottomBorder ? '#f0f3f6' : '#fff')};
  border-bottom-width: ${(props) => (props.bottomBorder ? '1px' : '0px')};
`;

const StatusSubText = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #21243c;
`;
const StatusSubLableText = styled.p`
  margin-top: 5px;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #302e6b;
`;

const Text3 = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 18px;
  flex: 1;
  font-size: 16px;
  line-height: 16px;
  color: ${(props) => (props.active ? '#21243C' : '#767787')};
`;

const ModalAttachContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  padding: 1rem 0.75rem;
  padding-bottom: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const AttachOptionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-self: stretch;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1.25rem;
  margin-top: 1rem;
`;

const AttachOption = styled(StyledTappable)`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: stretch;
  align-self: stretch;
  margin: 0 0.25rem;
`;

const AttachmentInput = styled.input`
  display: none;
`;

const AttachOptionInnerContainer = styled.div`
  display: flex;
  flex: 1;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  overflow: hidden;
`;

const AttachOptionText = styled.p`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: center;
`;

const AttachOptionIcon = styled.img`
  width: 3.875rem;
  margin-bottom: 0.5rem;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
`;

class ClaimsSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      loading: true,
      claimInfo: null,
      documents: [],
      documentsDeficient: [],
      documentsDeficientDetails: null,
      claimInfoV2: {},
      status: [],
      labReport: [],
      perscriptionFile: [],
      invoiceFile: [],
      borderColor: '#FF865C',
      invoiceFiles: [],
      labReports: [],
      showUploadOption: false,
      prescriptionFiles: [],
      invoiceFileParts: [],
      prescriptionFileParts: [],
      medsInvoiceFileParts: [],
      labsInvoiceFileParts: [],
      labsReportFileParts: [],
      merParts: [],
      showMessageModal: false,
      modalHeader: '',
      modalMessage: '',
      goBack: true,
    };
    if (props.location.state.claimId) {
      this.state.claimId = props.location.state.claimId;
    } else {
      this.props.history.goBack();
    }

    if (props.location.state.goBack !== null) {
      this.state.goBack = props.location.state.goBack;
    }
  }

  componentDidMount() {
    if (this.state.claimId) {
      this.getClaimStatusV2();
      this.getClaimStatus();
    }
  }

  getClaimStatus = () => {
    fetchClaimSummary(this.state.claimId, this.props.authToken)
      .then((res) => {
        const invoiceFile = res.claimInfo.documents.filter((ele) => {
          return ele.type === 'invoice';
        });
        const prescriptionFile = res.claimInfo.documents.filter((ele) => {
          return ele.type === 'prescription';
        });
        const merFile = res.claimInfo.documents.filter((ele) => {
          return ele.type === 'mer';
        });
        console.log(res);
        this.setState({
          loading: false,
          claimInfo: res.claimInfo,
          status: res.claimInfo.status,
          invoiceFile,
          prescriptionFile,
          merFile,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  getClaimStatusV2 = () => {
    fetchClaimSummaryV2(this.state.claimId, this.props.authToken)
      .then((res) => {
        const documentsDeficientDetails =
          res.claimInfo.status && res.claimInfo.status.length
            ? res.claimInfo.status.find(
                (s) =>
                  s.state === 'documents-deficient' &&
                  s.type === 'documents-incomplete'
              )
            : null;
        this.setState({
          claimInfoV2: res.claimInfo,
          documentsDeficient: res.claimInfo
            ? res.claimInfo.documentsDeficient
            : [],
          documentsDeficientDetails: documentsDeficientDetails || null,
          requestStatus: documentsDeficientDetails
            ? documentsDeficientDetails.state
            : null,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onAttachPhoto() {
    document.querySelector('#photoAttachment').click();
  }

  onAttachFile() {
    document.querySelector('#fileAttachment').click();
  }
  onImageDataReceive = () => {
    this.setState({ showModal: false });
    const inputElem = document.getElementById('photoAttachment');

    const file = inputElem.files[0];
    console.log({ file });
    if (this.state.imageType === 'invoice') {
      this.setState({
        invoiceFileParts: [
          ...this.state.invoiceFileParts,
          {
            name: 'invoiceFile',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'prescription') {
      this.setState({
        prescriptionFileParts: [
          ...this.state.prescriptionFileParts,
          {
            name: 'prescriptionFile',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'meds-invoice') {
      this.setState({
        medsInvoiceFileParts: [
          ...this.state.medsInvoiceFileParts,
          {
            name: 'medicineInvoice',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'labs-invoice') {
      this.setState({
        labsInvoiceFileParts: [
          ...this.state.labsInvoiceFileParts,
          {
            name: 'labInvoice',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'lab-report') {
      this.setState({
        labsReportFileParts: [
          ...this.state.labsReportFileParts,
          {
            name: 'labReport',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    } else if (this.state.imageType === 'mer') {
      this.setState({
        merParts: [
          ...this.state.merParts,
          {
            name: 'merFile',
            filename: file.name,
            data: file,
            type: 'image/jpeg',
          },
        ],
      });
    }
  };

  onPDFDataReceive = () => {
    this.setState({ showModal: false });
    const inputElem = document.getElementById('fileAttachment');

    const file = inputElem.files[0];
    if (this.state.imageType === 'invoice') {
      this.setState({
        invoiceFileParts: [
          ...this.state.invoiceFileParts,
          {
            name: 'invoiceFile',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'prescription') {
      this.setState({
        prescriptionFileParts: [
          ...this.state.prescriptionFileParts,
          {
            name: 'prescriptionFile',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'meds-invoice') {
      this.setState({
        medsInvoiceFileParts: [
          ...this.state.medsInvoiceFileParts,
          {
            name: 'medicineInvoice',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'labs-invoice') {
      this.setState({
        labsInvoiceFileParts: [
          ...this.state.labsInvoiceFileParts,
          {
            name: 'labInvoice',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'lab-report') {
      this.setState({
        labsReportFileParts: [
          ...this.state.labsReportFileParts,
          {
            name: 'labReport',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    } else if (this.state.imageType === 'mer') {
      this.setState({
        merParts: [
          ...this.state.merParts,
          {
            name: 'merFile',
            filename: file.name,
            data: file,
            type: 'application/pdf',
          },
        ],
      });
    }
  };
  updateReimbursement = (s) => {
    console.log({ state: this.state, s }, '[updateReimbursement]');
    this.setState({ uploadingImage: true });
    const { baseUrl, authToken } = this.props;
    console.log({ state: this.state, s }, '[updateReimbursement]');
    const {
      invoiceFileParts,
      prescriptionFileParts,
      prescriptionFiles,
      invoiceFiles,
      claimInfoV2,
      documentsDeficient,
      labsReportFileParts,
    } = this.state;
    logEvent('OPD Reimbursement Status Document Uploaded', {
      referenceId: claimInfoV2.reimbursementId,
      patientName: claimInfoV2.patientInfo
        ? claimInfoV2.patientInfo.name
        : null,
      procedureName: claimInfoV2.procedureName,
    });

    const formData = new FormData();
    formData.append('date', claimInfoV2.admissionDate);
    formData.append('reimbursementId', claimInfoV2.reimbursementId);
    formData.append(
      'requestStatus',
      s.type === 'documents-incomplete' && s.showDetails ? 1 : 0
    );
    if (
      ((prescriptionFiles.length > 0 || prescriptionFileParts.length > 0) &&
        (invoiceFileParts.length > 0 || invoiceFiles.length) &&
        !(s.type === 'documents-incomplete' && s.showDetails)) ||
      (((documentsDeficient.includes('prescription') &&
        prescriptionFileParts.length > 0) ||
        !documentsDeficient.includes('prescription')) &&
        ((documentsDeficient.includes('invoice') &&
          invoiceFileParts.length > 0) ||
          !documentsDeficient.includes('invoice')) &&
        ((documentsDeficient.includes('lab-report') &&
          labsReportFileParts.length > 0) ||
          !documentsDeficient.includes('lab-report')) &&
        s.type === 'documents-incomplete' &&
        s.showDetails)
    ) {
      if (invoiceFileParts.length > 0) {
        // body.push(invoiceFileParts);
        invoiceFileParts.forEach((file) => {
          formData.append('invoiceFile', file.data);
        });
      }
      if (labsReportFileParts.length > 0) {
        // body.push(labsReportFileParts);
        labsReportFileParts.forEach((file) => {
          formData.append('labReport', file.data);
        });
      }
      if (prescriptionFileParts.length > 0) {
        // body.push(prescriptionFileParts);
        prescriptionFileParts.forEach((file) => {
          formData.append('prescriptionFile', file.data);
        });
      }
      uploadDocuments(formData, authToken)
        .then((apiResponse) => {
          console.log({ apiResponse, baseUrl }, 'uploadFileUrl response');
          if (apiResponse.message === 'success') {
            this.setState({
              uploadingImage: false,
              showMessageModal: true,
              modalHeader: 'Additional Documents Submitted',
              modalMessage:
                'Please refer to the documents section below to see all uploaded documents',
            });
          } else if (apiResponse.message === 'failure') {
            this.setState({
              modalHeader: 'Error',
              showMessageModal: true,
              uploadingImage: false,
              modalMessage: apiResponse.errorMessage,
            });
          } else {
            this.setState({
              modalHeader: 'Image Not Supported',
              showMessageModal: true,
              uploadingImage: false,
              modalMessage:
                'File format not supported! Upload a jpg/jpeg image.',
            });
          }
        })
        .catch((err) => {
          console.log({ err }, 'error response while calling uploadFileUrl');
          this.setState({ uploadingImage: false });
        });
    } else {
      this.setState({ uploadingImage: false });
      alert('Oops', 'Please upload all required documents.');
    }
  };
  hideModal = () => {
    this.getClaimStatusV2();
    this.getClaimStatus();
    this.setState({
      showMessageModal: false,
    });
  };
  render() {
    const {
      invoiceFile,
      prescriptionFile,
      status,
      claimInfo,
      documentsDeficientDetails,
      documentsDeficient,
      requestStatus,
      labReport,
      borderColor,
      showModal,
      showUploadOption,
      invoiceFileParts,
      prescriptionFileParts,
      labsReportFileParts,
    } = this.state;
    console.log({ state: this.state }, 'renderUploadedFile');
    const renderUploadedFile = (ele, idx) => {
      const { fileUrl, data } = ele;
      console.log({ fileUrl, data }, 'renderUploadedFile');
      if (fileUrl && fileUrl.substr(fileUrl.length - 3) === 'pdf') {
        return (
          <AttachFileContainer
            key={fileUrl || window.URL.createObjectURL(data)}
            fileUrl={fileUrl || window.URL.createObjectURL(data)}
            onTap={() => {
              document.querySelector(`#image-anchor-${idx}`).click();
            }}
          >
            <a
              href={fileUrl || window.URL.createObjectURL(data)}
              target="_blank"
              rel="noopener noreferrer"
              id={`image-anchor-${idx}`}
              style={{ display: 'none' }}
            />
          </AttachFileContainer>
        );
      }
      return (
        <AttachImageContainer
          key={fileUrl || window.URL.createObjectURL(data)}
          fileUrl={fileUrl || window.URL.createObjectURL(data)}
          onTap={() => {
            document.querySelector(`#image-anchor-${idx}`).click();
          }}
        >
          <a
            href={fileUrl || window.URL.createObjectURL(data)}
            target="_blank"
            rel="noopener noreferrer"
            id={`image-anchor-${idx}`}
            style={{ display: 'none' }}
          />
        </AttachImageContainer>
      );
    };

    const stepsRender = (ele) => {
      return (
        <>
          <StepContainer
            style={
              ele.active === false
                ? { borderColor: '#333333' }
                : { borderColor: '#6544C6' }
            }
            inactive={ele.active === false || ele.dateTime != null}
          >
            {ele.active ? (
              <StatusIconImg src={tickIcon} alt="tick" />
            ) : (
              <StatusIcon completed={ele.active} active={ele.active} />
            )}
            <StepTextContainer completed={ele.active} active={ele.active}>
              <div className="txt-black-600-12">{ele.label}</div>
              <div className="txt-black-500-11">{ele.description}</div>
            </StepTextContainer>
          </StepContainer>
        </>
      );
    };
    return (
      <OuterContainer>
        <Header
          title="Summary"
          onTap={() => {
            if (this.state.goBack) {
              this.props.history.goBack();
            } else {
              this.props.history.replace('/home');
            }
          }}
        />
        {this.state.loading ? (
          <LoadingComponent />
        ) : (
          <BodyContainer>
            <div className="txt-black-600-14 pb-18">Claim status</div>
            <StepsContainer>{status.map(stepsRender)}</StepsContainer>
            {requestStatus === 'documents-deficient' &&
            documentsDeficientDetails &&
            documentsDeficient &&
            documentsDeficient.length > 0 ? (
              <div>
                <StatusContainer2 borderColor={borderColor}>
                  <StatusDetail bottomBorder>
                    <Text3 active>{documentsDeficientDetails.label}</Text3>
                    <StatusSubLableText>
                      {documentsDeficientDetails.description}
                    </StatusSubLableText>
                  </StatusDetail>

                  {documentsDeficientDetails.type === 'upload-document' ||
                  documentsDeficientDetails.type === 'documents-incomplete' ? (
                    <div>
                      {invoiceFileParts.length > 0 &&
                        documentsDeficient.includes('invoice') && (
                          <div>
                            <p className="txt-dgrey-600-12 mt-20 mb-5">
                              Invoice files
                            </p>

                            <AttachedFilesContainer>
                              {invoiceFileParts.map(renderUploadedFile)}
                              {invoiceFileParts.length > 0 &&
                                invoiceFileParts.length < 5 && (
                                  <div
                                    class="dashed-add"
                                    style={{
                                      marginTop: '0.25rem',
                                      marginBottom: '0.5rem',
                                      width: 45,
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        showModal: true,
                                        showUploadOption: true,
                                        imageType: 'invoice',
                                      });
                                    }}
                                  >
                                    +
                                  </div>
                                )}
                            </AttachedFilesContainer>
                          </div>
                        )}
                      {prescriptionFileParts.length > 0 &&
                        documentsDeficient.includes('prescription') && (
                          <div>
                            <p className="txt-dgrey-600-12 mt-20 mb-5">
                              Prescription files
                            </p>

                            <AttachedFilesContainer>
                              {prescriptionFileParts.map(renderUploadedFile)}
                              {prescriptionFileParts.length > 0 &&
                                prescriptionFileParts.length < 5 && (
                                  <div
                                    class="dashed-add"
                                    style={{
                                      marginTop: '0.25rem',
                                      marginBottom: '0.5rem',
                                      width: 45,
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        showModal: true,
                                        showUploadOption: true,
                                        imageType: 'prescriptionFiles',
                                      });
                                    }}
                                  >
                                    +
                                  </div>
                                )}
                            </AttachedFilesContainer>
                          </div>
                        )}
                      {labsReportFileParts.length > 0 &&
                        documentsDeficient.includes('lab-report') && (
                          <div>
                            <p className="txt-dgrey-600-12 mt-20 mb-5">
                              Lab Report
                            </p>

                            <AttachedFilesContainer>
                              {labsReportFileParts.map(renderUploadedFile)}
                              {labsReportFileParts.length > 0 &&
                                labsReportFileParts.length < 5 && (
                                  <div
                                    class="dashed-add"
                                    style={{
                                      marginTop: '0.25rem',
                                      marginBottom: '0.5rem',
                                      width: 45,
                                    }}
                                    onClick={() => {
                                      this.setState({
                                        showModal: true,
                                        showUploadOption: true,
                                        imageType: 'lab-report',
                                      });
                                    }}
                                  >
                                    +
                                  </div>
                                )}
                            </AttachedFilesContainer>
                          </div>
                        )}
                      {invoiceFileParts.length === 0 &&
                      documentsDeficient.includes('invoice') ? (
                        <p
                          className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              showUploadOption: true,
                              imageType: 'invoice',
                            });
                          }}
                        >
                          Upload Invoice
                        </p>
                      ) : (
                        ''
                      )}
                      {prescriptionFileParts.length === 0 &&
                      documentsDeficient.includes('prescription') ? (
                        <p
                          className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              showUploadOption: true,
                              imageType: 'prescription',
                            });
                          }}
                        >
                          Upload Prescription
                        </p>
                      ) : (
                        ''
                      )}
                      {labsReportFileParts.length === 0 &&
                      documentsDeficient.includes('lab-report') ? (
                        <p
                          className="txt-black-600-14 lab-home-btn-blue-dashed pt-10 pb-10 hover mt-20"
                          onClick={() => {
                            this.setState({
                              showModal: true,
                              showUploadOption: true,
                              imageType: 'lab-report',
                            });
                          }}
                        >
                          Upload Lab Report
                        </p>
                      ) : (
                        ''
                      )}
                    </div>
                  ) : null}
                  {documentsDeficientDetails.reason ? (
                    <StatusUploadSubContainer>
                      <StatusSubText>
                        {documentsDeficientDetails.reason}
                      </StatusSubText>
                    </StatusUploadSubContainer>
                  ) : null}
                  {(invoiceFileParts.length > 0 ||
                    prescriptionFileParts.length > 0 ||
                    labsReportFileParts.length > 0) &&
                  !(
                    documentsDeficientDetails.state === 'documents-uploaded'
                  ) ? (
                    <StatusDetail>
                      <p
                        className="txt-white-600-14 lab-home-btn-blue pt-10 pb-10 hover mt-20"
                        style={{
                          background: '#916EF7',
                        }}
                        onClick={() =>
                          this.updateReimbursement(documentsDeficientDetails)
                        }
                      >
                        Submit
                      </p>
                    </StatusDetail>
                  ) : null}
                </StatusContainer2>
                <DateText>
                  {documentsDeficientDetails.dateTime
                    ? documentsDeficientDetails.dateTime
                    : null}
                </DateText>
              </div>
            ) : null}
            <div className="txt-black-600-14 pt-17 pb-5">Details</div>
            <div className="order-status">
              <div className="order-status-details">
                <div className="flex-start-end align-items-center border-bottom pb-8">
                  <span className="txt-grey-600-10">Patient</span>
                  <span className="txt-black-600-12">
                    {claimInfo.patientInfo.name}
                  </span>
                </div>
                <div className="flex-start-end align-items-center border-bottom pb-8 pt-8">
                  <span className="txt-grey-600-10">Mobile</span>
                  <span className="txt-black-600-12">
                    {claimInfo.patientInfo.phone}
                  </span>
                </div>
                <div className="flex-start-end align-items-center pt-8">
                  <span className="txt-grey-600-10">Email</span>
                  <span className="txt-black-600-12">
                    {claimInfo.patientInfo.email}
                  </span>
                </div>
              </div>
            </div>
            {prescriptionFile.length > 0 && (
              <div className="order-status">
                <div className="order-status-details">
                  <div className="txt-black-600-12 border-bottom">
                    Consultation Papers/Prescription
                  </div>
                  <AttachedFilesContainer>
                    {prescriptionFile.map(renderUploadedFile)}
                  </AttachedFilesContainer>
                </div>
              </div>
            )}
            {invoiceFile.length > 0 && (
              <div className="order-status">
                <div className="order-status-details">
                  <div className="txt-black-600-12 border-bottom">
                    Indoor case papers
                  </div>
                  <AttachedFilesContainer>
                    {invoiceFile.map(renderUploadedFile)}
                  </AttachedFilesContainer>
                </div>
              </div>
            )}
            {labReport.length > 0 && (
              <div className="order-status">
                <div className="order-status-details">
                  <div className="txt-black-600-12 border-bottom">
                    Lab Reports
                  </div>
                  <AttachedFilesContainer>
                    {labReport.map(renderUploadedFile)}
                  </AttachedFilesContainer>
                </div>
              </div>
            )}
          </BodyContainer>
        )}
        <AttachmentInput
          type="file"
          id="photoAttachment"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={this.onImageDataReceive}
        />
        <AttachmentInput
          type="file"
          id="fileAttachment"
          accept="application/pdf"
          style={{ display: 'none' }}
          onChange={this.onPDFDataReceive}
        />
        {showModal && (
          <Modal>
            <ModalContainer onClick={this.handleOutsideClick}>
              <FlyInTransition
                in={showUploadOption}
                mountOnEnter
                unmountOnExit
                onExited={this.hideModal}
                appear
              >
                <ModalAttachContainer ref={(node) => (this.node = node)}>
                  <AttachOptionsContainer>
                    <AttachOption onTap={() => this.onAttachPhoto()}>
                      <AttachOptionInnerContainer>
                        <AttachOptionIcon src={photoIcon} />
                        <AttachOptionText>Gallery</AttachOptionText>
                      </AttachOptionInnerContainer>
                    </AttachOption>
                    <AttachOption onTap={() => this.onAttachFile()}>
                      <AttachOptionInnerContainer>
                        <AttachOptionIcon src={fileIcon} />
                        <AttachOptionText>pdf</AttachOptionText>
                      </AttachOptionInnerContainer>
                    </AttachOption>
                  </AttachOptionsContainer>
                </ModalAttachContainer>
              </FlyInTransition>
            </ModalContainer>
          </Modal>
        )}
        {this.state.showMessageModal ? (
          <MessageModal
            header={this.state.modalHeader}
            message={this.state.modalMessage}
            hideModal={this.hideModal}
          />
        ) : (
          ''
        )}
      </OuterContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(ClaimsSummary);
