import axios from 'axios';
import config from '../config';
import { store } from '../store';
import { showError } from '../actions';

window.audioContext = new window.AudioContext();

export function fetchSounds() {
  const newReward = '/audios/Voila.mp3';
  axios
    .get(newReward, {
      responseType: 'arraybuffer',
    })
    .then((res) => {
      const arrayBuffer = res.data;
      window.audioContext.decodeAudioData(arrayBuffer).then((audioBuffer) => {
        console.log('The actual sound', audioBuffer);
        window.newReward = audioBuffer;
      });
    })
    .catch((err) => {
      console.log(err);
    });
}
