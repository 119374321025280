import React, { Component } from 'react';
import styled from 'styled-components';
import Modal from '../../containers/common/Modal';
import { FadeInTransition } from '../transitions/transitions';

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  width: 650px;
`;

export default class SelectPolicyModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showUploadOption: true,
    };
  }

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };
  hideModal = () => {
    this.setState({
      showUploadOption: false,
    });
    setTimeout(this.props.hideModal, 200);
  };
  render() {
    const { allPolicies, selectedPolicy, heading, selectedMember, insuredMembers } = this.props;
    console.log(allPolicies);
    const renderPolicy = (allPolicies) => {
      return allPolicies.map((e) => {
        return (
          <div
            className="w-full border-t pt-16px pb-16px"
            onClick={() => {
              if (e.status !== 'InActive') {
                this.props.changePolicy(e);
              }
            }}
          >
            <div
              style={{
                color:
                  e.policyNo === selectedPolicy
                    ? '#01a39e'
                    : e.status === 'InActive'
                    ? 'red'
                    : '',
              }}
            >
              {e.policyName}
            </div>
          </div>
        );
      });
    };
    const renderMembers = (insuredMembers) => {
      return insuredMembers.map((e) => {
        return (
          <div
            className="w-full border-t pt-16px pb-16px"
            onClick={() => {
              if (e.status !== 'InActive') {
                this.props.changeMember(e);
              }
            }}
          >
            <div
              style={{
                color:
                  e.memberId === selectedMember.memberId
                    ? '#01a39e'
                    : e.status === 'InActive'
                    ? 'red'
                    : '',
              }}
            >
              {e.memberName}
            </div>
          </div>
        );
      });
    };
    return (
      <Modal>
        <ModalContainer onClick={this.handleOutsideClick}>
          <FadeInTransition
            in={this.state.showUploadOption}
            mountOnEnter
            appear
            timeout={200}
          >
            <div
              className="bg-textbox flex flex-col items-center rounded-12"
              ref={(node) => (this.node = node)}
            >
              <div
                className="p-24px flex flex-row items-center w-full justify-center rounded-t-12"
                style={{
                  background: 'rgb(58, 92, 129)',
                  color: '#ffffff',
                }}
              >
                <p
                  style={{
                    fontWeight: '600',
                    fontSize: '20px',
                  }}
                >
                  {heading}
                </p>
              </div>
              <div className="border-txtsecondary-shades4 hover w-full text-center mx-16px mb-16px flex flex-col items-center ">
                {allPolicies && renderPolicy(allPolicies)}
                {insuredMembers && renderMembers(insuredMembers)}
              </div>
            </div>
          </FadeInTransition>
        </ModalContainer>
      </Modal>
    );
  }
}
