import React, { Component } from 'react';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaAngleRight, FaArrowLeft } from 'react-icons/fa';
import { FadeInTransition } from '../../components/transitions/transitions';
import { Text } from '../../components/common/Text';
import { connect } from 'react-redux';
import LoadingComponent from '../../components/common/LoadingComponent';
import fitcoinIconWhite from '../../images/common/rewardiconwhite.png';
import UploadModal from '../../components/common/UploadModal';
import Modal from '../common/Modal';
import WellnessUploadModal from '../../components/common/WellnessUploadModal';
import DocumentSelector from './DocumentSelector';
import WellnessRewardStatus from './WellnessRewardStatus';
import {
  getAvailableRewards,
  getCompletedRewards,
} from '../../services/star-health';
import lottie from 'lottie-web';
import { EarnWellnessBadge } from '../../components/common/WellnessRewards';
import TappableCard from '../../components/common/TappableCard';
import User from '../../images/star-health-status/User.svg';
import Time from '../../images/star-health-status/time.svg';
import Tick from '../../images/star-health-status/Tick.svg';
import Cross from '../../images/star-health-status/cross.svg';
import StarIcon from '../../images/star-health-status/g-star.png';
import { Tabs, Tab } from '@tarragon/swipeable-tabs/dist';
import InfoIcon from '../../images/detailed-graph/Alert.svg';
import WellnessInfoModal from '../../components/common/WellnessInfoModal';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  overflow: hidden;
  height: 100vh;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.5rem;
  border-radius: 0px 0px 6px 6px;
`;

const BackContainer = styled(Tappable)`
  margin-right: 1.125rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
`;

const CongratulateModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 1);
`;

const ActivityHeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 2px;
  padding-left: 44px;
  padding-right: 44px;
  padding-top: 16px;
  font-size: 18px;
  border-bottom: 1px solid #b3b3b3;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
`;

const ActivityHeader = styled.div`
  width: 50%;
  color: #21243c;
  cursor: pointer;
  text-align: center;
  font-family: Manrope;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  font-size: 16px;
  padding-bottom: 8px;
`;

const ActivityContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  border-top: 0.5px solid #b3b3b3;
`;

const Head = styled.div`
  z-index: 10;
`;

const MainTag = styled.div`
  width: 100%;
  font-size: 16px;
  font-weight: 600;
`;

const SubTagContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 8px 0px;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
`;

const OuterTappable = styled(TappableCard)`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
  border: 1px solid #e3e3e3;
  font-weight: 500;
`;

const OuterContainer1 = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 24px;
  padding-top: 7px;
  padding-bottom: 5px;
`;

const Icon = styled.img`
  max-width: 3.625rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: stretch;
  font-size: 14px;
`;

const TitleText = styled(Text)`
  font-size: 14px;
  float: left;
  margin-right: 0.5rem;
  color: #21243c;
  margin-left: 7px;
`;

const SubtitleText = styled(Text)`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #a5aab0;
  width: 200px;
`;

const ArrowIcon = styled(FaAngleRight)`
  color: #21243c;
  font-size: 14px;
`;

const BodyRow = styled.div`
  padding: 6px;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  padding-left: 18px;
  padding-right: 24px;
  justify-content: center;
  align-items: flex-start;
`;

class WellnnessRewardTabContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      policyId: null,
      selectedItem: 'Available',
      showUploadModal: false,
      showDocumentSelector: false,
      selectedRewardId: null,
      availableData: [],
      completedData: [],
      validSize: false,
      validFile: null,
      selectedActivityId: null,
      setAnimation: false,
      submitId: null,
      apiLoading: false,
      detailedText: null,
      showInfoModal: false,
      memberId: '',
    };
    this.documentSubmit = React.createRef(null);

    const { location } = this.props;
    console.log(location.state);
    if (location && location.state) {
      this.state.policyId = location.state.policyId;
      this.state.selectedItem = location.state.currentState;
      this.state.memberId = location.state.memberId;
      console.log(location.state, 'Arrey');
      if (this.state.selectedItem === 'Available') {
        this.getAvailableRewards(this.props.authToken, this.state.policyId, this.state.memberId);
      } else {
        this.getCompletedRewards(this.props.authToken, this.state.policyId, this.state.memberId);
      }
    }
  }

  setSelectedItem = (e) => {
    this.setState({ selectedItem: e.label });
    if (e.label === 'Available') {
      this.getAvailableRewards(this.props.authToken, this.state.policyId, this.state.memberId);
    } else {
      this.getCompletedRewards(this.props.authToken, this.state.policyId, this.state.memberId);
    }
  };

  getCompletedRewards = (authToken, policyId, memberId) => {
    // this.setState({ apiLoading: true });
    getCompletedRewards(authToken, policyId, memberId)
      .then((response) => {
        console.log(response);
        this.setState({ completedData: response.result, apiLoading: false });
      })
      .catch((err) => console.log(err));
  };

  getAvailableRewards = (authToken, policyId, memberId) => {
    // this.setState({ apiLoading: true });
    console.log(memberId, 'come on');
    getAvailableRewards(authToken, policyId, memberId)
      .then((response) => {
        console.log(response);
        this.setState({ availableData: response.result, apiLoading: false });
      })
      .catch((err) => console.log(err));
  };

  hideModal = () => {
    this.setState({
      showUploadModal: false,
      showDocumentSelector: false,
      showInfoModal: false,
    });
  };

  hideDocumentModal = (value) => {
    this.setState({
      showDocumentSelector: false,
      setAnimation: true,
      submitId: value,
    });
    this.showSubmitAnimation();
  };

  handleOutsideClick = (e) => {
    // ignore clicks on the component itself
    if (this.node && this.node.contains(e.target)) {
      return;
    }
    this.hideModal();
  };

  setValidSizeAndFile = (value, file) => {
    this.setState({ validSize: value, validFile: file });
    console.log('valid Size: ', file);
    if (value) {
      this.setState({
        showUploadModal: false,
        showDocumentSelector: true,
      });
    }
  };

  crossClicked = () => {
    this.setState({
      validSize: false,
      validFile: null,
      showUploadModal: true,
      showDocumentSelector: false,
    });
  };

  setLoading = (value) => {
    this.setState({ loading: value });
  };

  showSubmitAnimation = () => {
    console.log('Hitting the Animation');
    lottie.loadAnimation({
      container: this.documentSubmit.current,
      loop: false,
      autoplay: true,
      animationData: require('../wellness-rewards/document-submited.json'),
    });
    setTimeout(() => {
      this.setState({
        setAnimation: false,
      });
      this.props.history.push('/home/wellness-reward-status', {
        policyId: this.state.policyId,
        rewardId: this.state.submitId,
        authToken: this.props.authToken,
        memberId: this.state.memberId,
      });
    }, 3500);
  };

  renderAvailableBody = () => {
    return (
      <div className="pl-4px pr-4px h-full">
        {this.state.availableData &&
          this.state.availableData.length > 0 &&
          this.state.availableData.map((e, i) => (
            <ActivityContainer
              style={
                i === 0
                  ? { border: 'none' }
                  : { borderTop: '0.5px solid black' }
              }
            >
              <SubTagContainer>
                <p className="mr-12px">{e.rewardName}</p>
                <div
                  style={{ color: '#00BB8E', cursor: 'pointer' }}
                  onClick={() => {
                    switch (e.action) {
                      case 'upload':
                        return this.setState({
                          showUploadModal: true,
                          selectedActivityId: e.rewardId,
                          detailedText: e.detailedText,
                        });
                      case 'redirect':
                        return this.props.history.push(e.redirectTo);
                      case 'none':
                        return null;
                      default:
                        return null;
                    }
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <EarnWellnessBadge points={e.activityPoints} />
                  </div>
                </div>
              </SubTagContainer>
            </ActivityContainer>
          ))}
      </div>
    );
  };
  renderCompletedBody = () => {
    return (
      <div className="pl-4px pr-4px h-full">
        {this.state.completedData.map((e) => (
          <OuterTappable
            onTap={() =>
              this.props.history.push('/home/wellness-reward-status', {
                policyId: e.userPolicyId,
                rewardId: e.rewardReqId,
                authToken: this.props.authToken,
                memberId: this.state.memberId,
              })
            }
          >
            <OuterContainer1>
              <TitleContainer>
                <div className="flex-center mb-4">
                  <TitleText>{e.rewardName}</TitleText>
                </div>
              </TitleContainer>
              <div>
                <ArrowIcon />
              </div>
            </OuterContainer1>
            <BodyRow>
              {e.rewardStatus === 'success' && (
                <div className="flex flex-row pb-4px">
                  <img
                    src={StarIcon}
                    className="mr-4px"
                    width={'16px'}
                    height={'20px'}
                    style={{
                      width: '16px',
                      height: '20px',
                    }}
                  />
                  <div style={{ color: '#a37520' }}>+ {e.activityPoints}</div>
                </div>
              )}
              <div className="flex flex-row pb-4px">
                <img src={User} className="mr-4px" />
                <div>{e.userName}</div>
              </div>
              <div className="flex flex-row justify-between w-full items-center">
                <div className="flex flex-row pb-4px">
                  <img src={Time} className="mr-4px" />
                  <div>{e.updatedAt}</div>
                </div>
                <div
                  className="flex flex-row pb-4px items-center pr-4px justify-start"
                  style={{ width: '30%' }}
                >
                  {e.rewardStatus != 'rejected' ? (
                    <>
                      <img src={Tick} className="mr-4px" width={'18px'} />
                      <div style={{ color: '#3F8CFF', fontWeight: '600' }}>
                        {e.rewardStatus[0].toUpperCase() +
                          e.rewardStatus.slice(1)}
                      </div>
                    </>
                  ) : (
                    <>
                      <img src={Cross} className="mr-4px" width={'18px'} />
                      <div style={{ color: '#FF865C', fontWeight: '600' }}>
                        {e.rewardStatus[0].toUpperCase() +
                          e.rewardStatus.slice(1)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </BodyRow>
          </OuterTappable>
        ))}
      </div>
    );
  };
  render() {
    return (
      <div className="overflow-auto w-full">
        {this.state.loading ? (
          <div className="p-20 center">
            <LoadingComponent />
          </div>
        ) : (
          <FadeInTransition
            in={true}
            timeout={200}
            mountOnEnter
            unmountOnExit
            appear
          >
            <OuterContainer>
              <Head>
                <HeaderContainer>
                  <BackContainer onTap={() => this.props.history.push('/home')}>
                    <StyledArrow />
                  </BackContainer>
                  <HeaderTitleContainer>
                    <HeaderText>Your Wellness Tasks</HeaderText>
                    <div onClick={() => this.setState({ showInfoModal: true })}>
                      <img src={InfoIcon} style={{ marginRight: '8px' }} />
                    </div>
                  </HeaderTitleContainer>
                </HeaderContainer>
              </Head>

              <BodyContainer>
                {this.state.apiLoading ? (
                  <div className="p-20 center">
                    <LoadingComponent />
                  </div>
                ) : (
                  // this.renderBody()
                  <div className="h-full">
                    <Tabs
                      value={this.state.selectedItem}
                      onChange={this.setSelectedItem}
                      tabBarCSS={`
                        font-weight: bold;
                        border-bottom: 0.5px solid #b3b3b3;
                        border-radius: 8px 8px 0px 0px;
                        box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
                      `}
                      className="h-full"
                      styleProps={{
                        selectedHeaderTextColor: 'Black',
                        barColor: 'white',
                        headerTextColor: '#b3b3b3',
                        size: 'medium',
                        activeInkBarColor: '#013adeb3',
                      }}
                    >
                      <Tab label="Available" key={0}>
                        {this.renderAvailableBody()}
                      </Tab>
                      <Tab label="Completed" key={1}>
                        {this.renderCompletedBody()}
                      </Tab>
                    </Tabs>
                  </div>
                )}
              </BodyContainer>
            </OuterContainer>
          </FadeInTransition>
        )}
        {this.state.showUploadModal && (
          <Modal>
            <ModalContainer onClick={this.handleOutsideClick}>
              <div ref={(node) => (this.node = node)} style={{ width: '100%' }}>
                <WellnessUploadModal
                  setValidSizeAndFile={this.setValidSizeAndFile}
                  detailedText={this.state.detailedText}
                />
              </div>
            </ModalContainer>
          </Modal>
        )}
        {this.state.showDocumentSelector && (
          <Modal>
            <ModalContainer onClick={this.handleOutsideClick}>
              <div ref={(node) => (this.node = node)} style={{ width: '100%' }}>
                <DocumentSelector
                  file={this.state.validFile}
                  crossClicked={this.crossClicked}
                  selectedActivityId={this.state.selectedActivityId}
                  authToken={this.props.authToken}
                  hideModal={this.hideModal}
                  history={this.props.history}
                  policyId={this.state.policyId}
                  setLoading={this.setLoading}
                  hideDocumentModal={this.hideDocumentModal}
                  memberId={this.state.memberId}
                />
              </div>
            </ModalContainer>
          </Modal>
        )}
        {this.state.setAnimation && (
          <Modal>
            <CongratulateModalContainer>
              <div
                className="document-submit"
                ref={this.documentSubmit}
                style={{ width: '50%' }}
              ></div>
            </CongratulateModalContainer>
          </Modal>
        )}
        {this.state.showInfoModal && (
          <Modal>
            <ModalContainer onClick={this.handleOutsideClick}>
              <WellnessInfoModal />
            </ModalContainer>
          </Modal>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(WellnnessRewardTabContainer);
