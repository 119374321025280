export const defaultState = {
  loginState: 'LOGGED_OUT',
  loginNumber: null,
  loginCountryCode: null,
  googleFitnessPermission: false,
  currentSteps: 0,
  currentSleep: '0',
  showWellnessAndHra: false,
  appError: {
    hasError: false,
    errorTitle: 'Something went wrong',
    errorDesc: 'Check your internet connection and try again',
  },
  config: {
    showQChat: false,
    showOPD: false,
    showSubscription: false,
    showMeds: false,
    showLabs: false,
    showFeed: false,
    showFitCoins: false,
    showOffline: false,
    showSymCheck: false,
    insured: false,
    transFitCoins: false,
    insurePortal: 'https://getvisitapp.com/buy-subscription.html',
    cancellationFee: 100,
    showManageDependent: false,
    showHraHeader: true,
    showUpdatedHra: false,
  },
};

export default function app(state = defaultState, action) {
  switch (action.type) {
    case 'OTP_SENT':
      return Object.assign({}, state, {
        loginState: action.type,
        loginNumber: action.payload.phoneNumber,
        loginCountryCode: action.payload.countryCode,
      });
    case 'UPDATE_LOGIN_STATE':
      return Object.assign({}, state, {
        loginState: action.payload.state,
      });
    case 'SHOW_ERROR': {
      return Object.assign({}, state, {
        appError: {
          hasError: true,
          errorTitle: action.payload.errorTitle,
          errorDesc: action.payload.errorDesc,
        },
      });
    }
    case 'HIDE_ERROR': {
      return Object.assign({}, state, {
        appError: {
          hasError: false,
          errorTitle: 'Something went wrong',
          errorDesc: 'Check your internet connection and try again',
        },
      });
    }
    case 'SET_APP_CONFIG': {
      const updatedConfig = Object.assign(
        {},
        state.config,
        action.payload.config
      );
      console.log(updatedConfig, action.payload.config);
      return Object.assign({}, state, {
        config: {
          ...updatedConfig,
          showUpdatedHra: action.payload.config.showUpdatedHra || false,
        },
      });
    }
    case 'SET_GOOGLE_FIT_PERMISSION': {
      return Object.assign({}, state, {
        googleFitnessPermission: action.payload.permissionForGoogleFit,
        currentSteps: action.payload.currentSteps,
        currentSleep: action.payload.currentSleep,
      });
    }
    case 'SET_WELLNESS_AND_HRA': {
      return Object.assign({}, state, {
        showWellnessAndHra: action.payload,
      });
    }
    default:
      return state;
  }
}
