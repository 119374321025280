import { select } from 'd3';
import React, { Component } from 'react';
import styled from 'styled-components';
import CrossIcon from '../../images/common/cross.svg';
import {
  getDocumentRelated,
  getDocumentType,
  submitActivityDocuments,
} from '../../services/star-health';

const ModalBoundary = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  color: #ffffff;
`;

const InnerBoundary = styled.div`
  width: 100%;
  padding: 20px;
  font-size: 12px;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #3a5c81;
  border-radius: 8px 8px 0px 0px;
  padding: 18px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #3a5c81;
  font-weight: 700;
  margin: 8px;
`;

const DocumentInput = styled.input`
  width: 50%;
  border: 1px solid black;
  font-size: 12px;
  border-radius: 4px;
  padding: 2px 4px;
`;

const Select = styled.select`
  width: 50%;
  border: 1px solid black;
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 4px;
`;

const Button1 = styled.button`
  width: 100%;
  padding: 14px;
  font-size: 14px;
  background-color: #00bb8e;
  color: #ffffff;
  border-radius: 8px;
`;

const ButtonD = styled.button`
  width: 100%;
  padding: 14px;
  font-size: 14px;
  background-color: grey;
  color: #ffffff;
  border-radius: 8px;
`;

const Option = styled.option`
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: black;
  backgroud: #ffffff;
`;

export default class DocumentSelector extends Component {
  constructor(props) {
    super(props);

    this.state = {
      type: [],
      belongs: [],
      related: [],
      selectedType: '',
      selectedTypeId: '',
      selectedBelongs: '',
      selectedRelated: '',
      selectedBelongsId: null,
      selectedRelatedId: '',
      selectedUserId: '',
      name: null,
      describe: null,
      disableSubmit: false,
    };
  }

  componentDidMount() {
    this.getDocumentType();
  }

  getDocumentType = () => {
    getDocumentType(this.props.authToken, this.props.selectedActivityId)
      .then((response) => {
        console.log(response);
        this.setState({
          belongs: response.dependents,
          type: response.documentType,
        });
      })
      .catch((err) => console.log(err));
  };

  getDocumentRelated = (id) => {
    getDocumentRelated(this.props.authToken, id)
      .then((response) => {
        console.log(response);
        this.setState({
          related: response.result,
        });
      })
      .catch((err) => console.log(err));
  };

  submitDocument = () => {
    this.setState({ disableSubmit: true });
    let data = new FormData();
    data.append('file', this.props.file);
    data.append('rewardId', this.props.selectedActivityId);
    data.append('memberId', this.props.memberId);
    data.append('userPolicyId', this.props.policyId);
    data.append('documentTypeId', null);
    data.append('documentBelongingTo', null);
    data.append('documentRelatedToId', null);
    data.append('documentName', null);
    data.append('documentDescription', this.state.describe);
    submitActivityDocuments(this.props.authToken, data)
      .then((response) => {
        console.log(response);
        // this.props.history.push('/home/wellness-reward-status', {
        //   policyId: this.props.policyId,
        //   rewardId: response.submitId,
        //   authToken: this.props.authToken,
        // });
        this.props.hideDocumentModal(response.submitId);
      })
      .catch((err) => {
        this.props.hideModal();
        console.log(err);
      });

    // this.props.setLoading(true);
  };

  render() {
    const {
      selectedType,
      selectedBelongs,
      selectedRelated,
      name,
      describe,
      type,
      belongs,
      related,
    } = this.state;
    return (
      <ModalBoundary>
        <HeaderContainer>
          <Title>Upload Records</Title>
        </HeaderContainer>
        <InnerBoundary>
          <Row>
            <p>
              Choosed file to upload <b style={{ color: 'red' }}>*</b>:
            </p>
            <div
              className="flex flex-row items-center"
              style={{ width: '50%', position: 'relative' }}
            >
              <DocumentInput
                type="text"
                value={this.props.file.name}
                disabled="true"
                style={{ width: '100%', paddingRight: '24px' }}
              />
              <img
                src={CrossIcon}
                width="12px"
                height="12px"
                style={{ position: 'absolute', right: '3%' }}
                onClick={this.props.crossClicked}
              />
            </div>
          </Row>
          {/* <Row>
            <p>
              Document type <b style={{ color: 'red' }}>*</b>:
            </p>
            <Select
              value={selectedType}
              onChange={(e) => {
                this.setState({ selectedType: e.target.value });
                const selected = type.filter(
                  (s) => s.name === e.target.value
                )[0];
                console.log(selected);
                this.setState({ selectedTypeId: selected.documentTypeId });
                this.getDocumentRelated(selected.documentTypeId);
              }}
            >
              <Option style={{ display: 'none' }}></Option>
              {type &&
                type.length > 0 &&
                type.map((e) => {
                  return <Option>{e.name}</Option>;
                })}
            </Select>
          </Row>
          <Row>
            <p>
              Document belongs to <b style={{ color: 'red' }}>*</b>:
            </p>
            <Select
              value={selectedBelongs}
              onChange={(e) => {
                this.setState({ selectedBelongs: e.target.value });
                const selected = belongs.filter(
                  (s) => s.name === e.target.value
                )[0];
                console.log(selected.userId);
                this.setState({ selectedBelongsId: selected.userId });
              }}
            >
              <Option style={{ display: 'none' }}></Option>
              {belongs &&
                belongs.length > 0 &&
                belongs.map((e) => {
                  return <Option>{e.name}</Option>;
                })}
            </Select>
          </Row>
          <Row>
            <p>
              Document related to <b style={{ color: 'red' }}>*</b>:
            </p>
            <Select
              value={selectedRelated}
              onChange={(e) => {
                this.setState({ selectedRelated: e.target.value });
                const selected = related.filter(
                  (s) => s.label === e.target.value
                )[0];
                console.log(selected);
                this.setState({
                  selectedRelatedId: selected.documentRelatedToId,
                });
              }}
            >
              <Option style={{ display: 'none' }}></Option>
              {related &&
                related.length > 0 &&
                related.map((e) => {
                  return <Option>{e.label}</Option>;
                })}
            </Select>
          </Row>
          <Row>
            <p>Name your record :</p>
            <DocumentInput
              type="text"
              value={name}
              onChange={(e) => this.setState({ name: e.target.value })}
            />
          </Row> */}
          <Row>
            <p>Describe :</p>
            <DocumentInput
              type="text"
              value={describe}
              onChange={(e) => this.setState({ describe: e.target.value })}
            />
          </Row>
          {/* {selectedType != '' &&
          selectedBelongs != '' &&
          selectedRelated != '' ? (
            ) : (
              <ButtonD disabled={true}>Add</ButtonD>
              )} */}
          <Button1
            onClick={this.submitDocument}
            disabled={this.state.disableSubmit}
          >
            Add
          </Button1>
        </InnerBoundary>
      </ModalBoundary>
    );
  }
}
