import axios from 'axios';
import config from '../config';
import { showError } from '../utils';
import { logEvent } from '../utils/logEvent';

export function login(phoneNumber, countryCode, encData, resend, platform) {
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      const body = {
        phone: phoneNumber,
        countryCode,
        platform: 'PHONEPE',
      };
      if (resend) {
        body['resend'] = true;
      }
      return axios
        .post(`${config.apiBaseUrl}/new-auth/login-phone`, body)
        .then((resp) => {
          const responseBody = resp.data;
          if (responseBody.message === 'otpSent') {
            return responseBody;
          }
          const err = new Error('Invalid response');
          err.data = responseBody;
          showError(err);
          throw err;
        })
        .catch((err) => {
          showError(err);
          throw err;
        });
    })
    .catch((err) => {
      const body = {
        phone: phoneNumber,
        countryCode,
        platform: platform || 'STAR-HEALTH',
        encData,
      };
      if (resend) {
        body['resend'] = true;
      }
      return axios
        .post(`${config.apiBaseUrl}/new-auth/login-phone`, body)
        .then((resp) => {
          const responseBody = resp.data;
          if (responseBody.message === 'otpSent') {
            return responseBody;
          }
          const err = new Error('Invalid response');
          err.data = responseBody;
          showError(err);
          throw err;
        })
        .catch((err) => {
          showError(err);
          throw err;
        });
    });
}

export function loginMagicLink(magicLink) {
  const body = {
    installReferrer: magicLink,
    platform: 'STAR-HEALTH',
  };
  return axios
    .post(`${config.apiBaseUrl}/new-auth/magic`, body)
    .then((resp) => {
      const responseBody = resp.data;
      const responseHeaders = resp.headers;
      if (responseBody.message === 'success') {
        return {
          headers: responseHeaders,
          body: responseBody,
        };
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function loginPhonepe(grantToken) {
  const body = {
    grantToken,
  };
  return axios
    .post(`${config.apiBaseUrl}/new-auth/phonepe/sso`, body)
    .then((resp) => {
      const responseBody = resp.data;
      const responseHeaders = resp.headers;
      if (responseBody.message === 'success') {
        return {
          headers: responseHeaders,
          body: responseBody,
        };
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      showError(err);
      throw err;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function verifyOtp(phoneNumber, otp, platform) {
  return window.PhonePe.PhonePe.build(window.PhonePe.Constants.Species.web)
    .then((sdk) => {
      const body = {
        phone: phoneNumber,
        otp,
        platform: 'PHONEPE',
      };
      return axios
        .post(`${config.apiBaseUrl}/new-auth/otp`, body)
        .then((resp) => {
          console.log(resp, 'resp in verify otp');
          const responseBody = resp.data;
          const responseHeaders = resp.headers;
          if (responseBody.message === 'otpVerified') {
            // logEvent('Onboarding OTP Verified');
            return {
              headers: responseHeaders,
              body: responseBody,
            };
          }
          const err = new Error('Invalid response');
          err.data = responseBody;
          throw err;
        });
    })
    .catch((err) => {
      if (err.message === 'Invalid response') {
        throw err;
      } else {
        const body = {
          phone: phoneNumber,
          otp,
          platform: platform || 'STAR-HEALTH',
          referrer: window.baseLandingUrl,
        };
        return axios
          .post(`${config.apiBaseUrl}/new-auth/otp`, body)
          .then((resp) => {
            console.log(resp, 'resp in verify otp');
            const responseBody = resp.data;
            const responseHeaders = resp.headers;
            if (responseBody.message === 'otpVerified') {
              return {
                headers: responseHeaders,
                body: responseBody,
              };
            }
            const err = new Error('Invalid response');
            err.data = responseBody;
            throw err;
          });
      }
    });
}

export function submitUserDetails(name, dob, gender, authToken) {
  const body = {
    name,
    dob,
    gender,
  };

  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/new-auth/register-details`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'userUpdated') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function userInfo(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/new-auth/login-info?v=123`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      const responseCode = resp.status;
      if (responseCode === 200 || responseCode === 304) {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function appConfig(authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/new-auth/config`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function verifyCorporate(email, authToken) {
  const body = {
    email,
  };

  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/new-auth/verify-b2b`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'sponsorFound') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      throw err;
    });
}

export function confirmEmail(email, authToken) {
  const body = {
    email,
  };

  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/new-auth/confirm-add-email`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'emailAdded') {
        return responseBody;
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      throw err;
    });
}

export function ssoLogin(encrypted, clientId) {
  const body = {
    encrypted,
    clientId,
  };

  return axios
    .post(`${config.apiBaseUrl}/new-auth/visit-sso`, body)
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return {
          headers: resp.headers,
          body: resp.data,
        };
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      throw err;
    });
}

export function callMetaApi(authToken) {
  const headers = {
    authorization: authToken,
  };
  const params = {
    version: config.version,
  };
  return axios
    .get(`${config.apiBaseUrl}/users/meta`, {
      params,
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return {
          headers: resp.headers,
          body: resp.data,
        };
      }
      const err = new Error('Invalid response');
      err.data = responseBody;
      throw err;
    })
    .catch((err) => {
      throw err;
    });
}
