/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaCheck, FaRegFilePdf } from 'react-icons/fa';

import { Text, RegularText } from '../../common/Text';
import StyledTappable from '../../common/StyledTappable';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  max-width: 80%;
  align-items: center;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 0.25rem 0;
  overflow: hidden;
`;

const BubbleContainer = styled(StyledTappable)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex: 1;
  overflow: hidden;
  padding: 0.625rem 0.75rem;
  background: rgba(228, 220, 248, 0.2);
  border-radius: 0.875rem;
`;

const MessageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FileIcon = styled(FaRegFilePdf)`
  width: 1rem;
  margin-right: 0.5rem;
  color: #013ade;
`;

const FileName = styled.p`
  flex: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #21243c;
`;

const TimestampContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: 1rem;
`;

const TickIcon = styled(FaCheck)`
  color: #fff;
  opacity: 0.5;
  font-size: ${() => `${0.625 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  margin-right: 0.25rem;
`;

const TimestampText = styled(RegularText)`
  color: #fff;
  opacity: 0.5;
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const UserDocumentMessage = ({
  fileName,
  url,
  messageId,
  timestamp,
  sponsorId,
}) => (
  <OuterContainer>
    <BubbleContainer
      onTap={() => {
        const userAgent = navigator.userAgent || navigator.vendor;

        if (window.Android) {
          return window.open(url, '_blank');
        } else if (
          window.webkit &&
          window.webkit.messageHandlers.visitIosView
        ) {
          window.webkit.messageHandlers.visitIosView.postMessage(
            JSON.stringify({
              method: 'openPDF',
              url,
            })
          );
        }
        // else if (sponsorId === 33 || sponsorId === 134) {
        else if (/iPad|iPhone|iPod/.test(userAgent)) {
          const anchor = document.createElement('a');
          anchor.setAttribute('href', `didtap://${encodeURIComponent(url)}`);
          anchor.click();
          return;
        }
        // }
        document.querySelector(`#${messageId}`).click();
      }}
    >
      <MessageContainer>
        <FileIcon />
        <FileName>{fileName}</FileName>
      </MessageContainer>
      {timestamp && (
        <TimestampContainer>
          <TickIcon />
          <TimestampText>
            {new Date(timestamp).toLocaleTimeString('nu', {
              hour: 'numeric',
              minute: '2-digit',
            })}
          </TimestampText>
        </TimestampContainer>
      )}
    </BubbleContainer>
    <a
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      id={messageId}
      style={{ display: 'none' }}
    />
  </OuterContainer>
);

const mapStateToProps = (state) => ({
  sponsorId: state.user.sponsorId,
});

UserDocumentMessage.propTypes = {
  fileName: PropTypes.string,
  url: PropTypes.string,
  messageId: PropTypes.string,
  timestamp: PropTypes.number,
};

export default connect(mapStateToProps)(UserDocumentMessage);
