import { Component } from 'react';
import styled from 'styled-components';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft, FaChevronRight } from 'react-icons/fa';
import {
  FadeInTransition,
  FlyInTransition,
} from '../../components/transitions/transitions';
import { Text } from '../../components/common/Text';
import DatePicker from 'react-datepicker';
import { connect } from 'react-redux';
import Modal from '../common/Modal';
import LoadingComponent from '../../components/common/LoadingComponent';
import axios from 'axios';
import config from '../../config';
import { showError } from '../../utils';
import { getWellnessPolicies } from '../../services/star-health';
import moment from 'moment';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  background: white;
  padding: 0.5rem;
  z-index: 10;
  user-select: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 6px 6px;
`;

const BackContainer = styled(Tappable)`
  margin-right: 1.125rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 16px;
`;

const Select = styled.select`
  width: 100%;
  padding: 6px;
  border: 1px solid #6544c6;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 15px;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #242327;
`;

const Option = styled.option`
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #242327;
`;

const Label = styled.label`
  top: calc(50% - 12px / 2 - 134.5px);
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 12px;
  color: #4e4c54;
  margin: 15px 6px 5px 2px;
  margin-left: 6px;
  padding: 4px 0px;
`;

const Value = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(79, 52, 210, 0.05);
  border-radius: 8px;
  padding: 10px 15px;
  margin-top: 4px;
  font-size: 14px;
  width: 100%;
`;

const Tags = styled.label`
  height: 12px;
  left: 6.4%;
  right: 6.93%;
  top: calc(50% - 12px / 2 + 37.5px);
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 12px;
  color: #000000;
  margin-top: 5px;
`;

const PolicyDataContainer = styled.div`
  width: 100%;
  min-width: 326px;
  background: #ffffff;
  border-radius: 8px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Input = styled.input`
  width: 100%;
  height: 36px;
  border: 1px solid #6544c6;
  box-sizing: border-box;
  border-radius: 8px;
  padding-left: 16px;
  padding-right: 16px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #21243c;
`;

const DisabledButton = styled.button`
  margin-top: 14px;
  border-radius: 8px;
  border-bottom: 14px;
  padding: 12px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background: grey;
  color: white;
  font-size: 16px;
  font-weight: 600;
`;

const LeftElement = styled.p`
  display: inline-block;
  float: left;
  padding: 4px;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #404040;
`;

const RightElement = styled.p`
  display: inline-block;
  float: right;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  padding: 4px;
  font-weight: 500;
  font-size: 14px;
  color: #404040;
`;

const TableDiv = styled.div`
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  padding: 4px;
`;

const CorporateImage = styled.img`
  width: 68px;
  height: 9.76px;
  display: inline-block;
  float: right;
  margin-top: 15px;
  margin-right: 16px;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const SelectButton = styled.button`
  margin-top: 14px;
  border-radius: 8px;
  border-bottom: 14px;
  padding: 12px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background: #013adeb3;
  color: white;
  font-size: 16px;
  font-weight: 600;
`;

class WellnessPolicy extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      allPolicies: [],
      selectedPolicy: null,
      policyName: null,
      holderName: null,
      startDate: null,
      expiryDate: null,
      status: null,
      policyId: null,
      from: null,
    };
    const { location } = this.props;
    if (location && location.state) {
      console.log(location.state.from);
      this.state.from = location.state.from;
    }
  }

  componentDidMount() {
    this.getWellnessPolicies();
  }

  getWellnessPolicies = () => {
    getWellnessPolicies(this.props.authToken)
      .then((response) => {
        console.log(response);
        this.setState({ allPolicies: response.result });
        this.setPolicy(response.result[0].policyNo);
        this.setState({ loading: false });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  setPolicy = (value) => {
    const details = this.state.allPolicies.filter(
      (e) => e.policyNo === value
    )[0];
    console.log(details);
    this.setState({
      policyName: details.policyName,
      holderName: details.name,
      startDate: moment(details.policyStartDate).format('ll'),
      expiryDate: moment(details.policyEndDate).format('ll'),
      status: details.status,
      policyId: details.userPolicyId,
      selectedPolicy: details.policyNo,
    });
  };

  moveNext = () => {
    if (this.state.from === 'lab') {
      this.props.history.push('/lab-test', {
        policyId: this.state.policyId,
      });
    } else {
      this.props.history.push('/home/wellness-rewards', {
        policyId: this.state.policyId,
        currentState: 'Available',
      });
    }
  };

  render() {
    const {
      allPolicies,
      selectedPolicy,
      policyName,
      holderName,
      startDate,
      expiryDate,
      status,
    } = this.state;
    return (
      <div className="overflow-auto w-full">
        {this.state.loading ? (
          <div className="p-20 center">
            <LoadingComponent />
          </div>
        ) : (
          <FadeInTransition
            in={true}
            timeout={200}
            mountOnEnter
            unmountOnExit
            appear
          >
            <OuterContainer>
              <HeaderContainer>
                <BackContainer onTap={() => this.props.history.push('/home')}>
                  <StyledArrow />
                </BackContainer>
                <HeaderTitleContainer>
                  <HeaderText>Select Policy</HeaderText>
                </HeaderTitleContainer>
              </HeaderContainer>
              <BodyContainer>
                <Label>Select Policy</Label>
                <Select
                  value={selectedPolicy}
                  onChange={(e) => this.setPolicy(e.target.value)}
                >
                  {allPolicies.map((e) => {
                    return <Option>{e.policyNo}</Option>;
                  })}
                </Select>
                <Label>Policy Name</Label>
                <Value>{policyName}</Value>
                <Label>Holder Name</Label>
                <Value>{holderName}</Value>
                <Label>Start Date</Label>
                <Value>{startDate}</Value>
                <Label>Expiry Date</Label>
                <Value>{expiryDate}</Value>
                <Label>Policy Status</Label>
                <Value
                  style={
                    status === 'Active' ? { color: 'green' } : { color: 'red' }
                  }
                >
                  {status}
                </Value>
                {status === 'Active' ? (
                  <SelectButton onClick={this.moveNext}>Select</SelectButton>
                ) : (
                  <DisabledButton disabled={true}>Select</DisabledButton>
                )}
              </BodyContainer>
            </OuterContainer>
          </FadeInTransition>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(WellnessPolicy);
