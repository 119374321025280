import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { FaTimes as RemoveIcon, FaAngleRight } from 'react-icons/fa';

import {
  dashboardApi,
  getConsultationSummary,
  homeTab,
  fetchConsultationBlocker,
  getNutritionDetails,
} from '../../services';
import {
  setFitcoinsBalance,
  setGoogleFitPermission,
  setWellnessAndHraVisibility,
  setWellnessBalance,
  showError,
} from '../../actions';

import LoadingComponent from '../../components/common/LoadingComponent';
import { BoldText, Text, ButtonText } from '../../components/common/Text';
import StyledTappable from '../../components/common/StyledTappable';
import DashboardActionCard from '../../components/home/DashboardActionCard';
import HealthActionCard from '../../components/home/HealthActionCard';
import HomeActionCard from '../../components/home/HomeActionCard';
import QuickActionCard from '../../components/home/QuickActionCard';
import SubscriptionCard from '../../components/home/SubscriptionCard';
import RewardsCard from '../../components/home/RewardsCard';
import ReferCard from '../../components/home/ReferCard';
import {
  FadeInTransition,
  FlyInTransition,
} from '../../components/transitions/transitions';
import Modal from '../common/Modal';
import UploadPrescription from '../consultation/UploadPrescription';

import treatIcon from '../../images/home/treat.svg';
import fitcoinLine from '../../images/common/fitcoin-line.png';
import fitcoinIconWhite from '../../images/common/rewardiconwhite.png';
import specialistIcon from '../../images/home/specialist.svg';
import labTestIcon from '../../images/home/lab-test.svg';
import medDelIcon from '../../images/home/med-del.svg';
import mealLogIcon from '../../images/home/meal-log.svg';
import metabolicProfileIcon from '../../images/home/metabolic-profile.svg';
import hraIcon from '../../images/home/hra.png';
import giftIcon from '../../images/common/gift-gold-icon.svg';
import backgroundPattern from '../../images/common/pattern.png';
import { RegularText } from '../../components/common/Text';
import { FitcoinButton } from '../../components/common/Buttons';
import symCheckIcon from '../../images/home/sym.svg';
import labIcon from '../../images/home/lab.svg';
import onlineIcon from '../../images/home/online.svg';
import offlineIcon from '../../images/home/offline.svg';
import medIcon from '../../images/home/med.svg';
import mealIcon from '../../images/home/meal.svg';
import metaIcon from '../../images/home/meta.png';
import PolicyActivationCard from '../../components/home/PolicyActivationCard';
import ImageCard from '../../components/home/ImageCard';
import ChatIcon from '../../images/home/chatIcon.png';
import membersIcon from '../../images/home/members.svg';
import initializeFreshDesk from '../../utils/freshchat';
import { logEvent } from '../../utils/logEvent';
import TinderBenefitsCard from '../../components/home/TinderBenefitsCard';
import ActivityTracker from '../../components/home/ActivityTracker';
import GoalProgressCard from '../../components/home/GoalProgressCard';
import axios from 'axios';
import config from '../../config';
import WaterLogCard from '../../components/home/WaterLogCard';
import CongratulationModal from './CongratulationModal';
import {
  getCompletedRewardsAlert,
  fitnessSyncReward,
  getConfig,
} from '../../services/star-health';

const BodyContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 3rem;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  padding: 0 0.5rem;
  margin-top: 0.4rem;
  margin-bottom: 1.5rem;
`;

const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0;
  max-width: 100%;
`;

const StyledActionCard = styled(DashboardActionCard)`
  margin-right: ${(props) => (props.marginRight ? props.marginRight : '0rem')};
  flex: 1 0;
`;

const QuickActionsContainer = styled.div`
  display: flex;
  align-self: stretch;
  align-items: stretch;
  flex-shrink: 0;
  margin-top: 0.4rem;
  margin-bottom: 1.5rem;
  overflow: scroll;
`;

const StyledQuickActionCard = styled(QuickActionCard)`
  width: 27%;
  flex: none;
`;

const ModalContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: stretch;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.7);
`;

const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  background-image: ${() => `url(${backgroundPattern})`};
  background-repeat: repeat;
  padding: 0.75rem;
  border-radius: 0.625rem 0.625rem 0 0;
`;

const CloseContainer = styled(StyledTappable)`
  align-self: flex-end;
  padding: 0.5rem;
`;

const CloseIcon = styled(RemoveIcon)`
  color: white;
`;

const GiftImage = styled.img`
  width: 4rem;
  margin-bottom: 1.125rem;
`;

const LineSeparator = styled.img`
  width: 10rem;
  margin-bottom: 0.875rem;
`;

const ModalTitle = styled(BoldText)`
  font-size: ${() => `${1.125 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const StepsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const StepContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 1rem 0;
`;

const StepNumber = styled(RegularText)`
  font-size: ${() => `${0.875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  border: 1px solid white;
  color: white;
  width: 2rem;
  height: 1.5rem;
  border-radius: 3rem;
`;

const StepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 1.125rem;
`;

const StepTextTile = styled(Text)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  color: white;
`;

const StepTextSubtitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StepTextSubtitle = styled(RegularText)`
  font-size: ${() => `${0.75 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  text-align: left;
  opacity: 0.6;
  color: white;
`;

const FitcoinIcon = styled.img`
  width: 0.75rem;
  height: 0.75rem;
  margin-left: 0.25rem;
`;

const ArrowIcon = styled(FaAngleRight)`
  color: #013ade;
`;

const CongratulateContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
`;

class HomeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardApiLoaded: false,
      servicesCards: [],
      fitCoinsCards: [],
      blockers: {},
      congratulateModal: false,
      rewardData: null,
      tinderBenefits: null,
      alertApiCalled: false,
      quickActionCards: [
        {
          cardType: 'meal-log',
          collapse: false,
          pendingConsult: 0,
          cardDirective: {
            actionType: 'native',
            action: { redirectTo: 'meal-log' },
          },
          labelIcon: 'search',
          label: 'Log Meal',
          labelInfo: 'Find a doctor nearby',
          actionIcon: 'arrow',
        },
        // {
        //   cardType: 'metabolic-profile',
        //   collapse: false,
        //   pendingConsult: 0,
        //   cardDirective: {
        //     actionType: 'bot-chat',
        //     action: {
        //       entryPoint: 'log-meal-fitness-goal',
        //       evidenceId: 'go',
        //       flowType: 'fitnessGoal',
        //       text: 'Metabolic Profile',
        //     },
        //   },
        //   labelIcon: 'search',
        //   label: 'Metabolic Profile',
        //   labelInfo: 'Find a doctor nearby',
        //   actionIcon: 'arrow',
        // },
      ],
      hraCard: [
        {
          cardType: 'hra-card',
          collapse: false,
          pendingConsult: 0,
          cardDirective: {
            actionType: 'native',
            action: {
              redirectTo: 'hra',
            },
          },
          labelIcon: 'search',
          label: 'Metabolic Profile',
          labelInfo: 'Find a doctor nearby',
          actionIcon: 'arrow',
        },
      ],
      opdCards: [],
      inviteCards: [],
      showModal: false,
      modalVisible: false,
      showPendingConsultBlocker: false,
      pendingConsult: {
        doctorId: '',
        consultationId: '',
        doctorImg: '',
        doctorName: '',
        dateLabel: '',
      },
      enrollmentCard: [],
      currentCals: 0,
      totalCals: 0,
      steps: {},
      meals: {},
      sleep: {},
      healthGoals: {},
      ahcCard: null,
    };
  }

  componentDidMount() {
    console.log('mounted');
    window.addEventListener('popstate', this.closeViewEvent, false);

    // GA Tracking

    console.log('The history object', this.props);
    logEvent('Home Tab');
    this.getConfig();
    this.getCompletedRewardsAlert();
    this.callDashboardApi();
    this.homeTab();
    // this.fetchConsultationBlocker();
    const { dispatch, location, authToken, userId } = this.props;
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const permissionForGoogleFit = params.get('fitnessPermission') === 'true';
      const NoOfSteps = params.get('steps');
      const NoOfSleep = params.get('sleep');
      const data = {
        permissionForGoogleFit,
        currentSteps: NoOfSteps,
        currentSleep: NoOfSleep,
      };
      dispatch(setGoogleFitPermission(data));
      this.fitnessSyncReward();
    }
    if (this.props.googleFitnessPermission) {
      this.fitnessSyncReward();
      if (location.search) {
        const params = new URLSearchParams(location.search);
        const permissionForGoogleFit =
          params.get('fitnessPermission') === 'true';
        if (!permissionForGoogleFit) {
          if (
            window.Android !== undefined &&
            window.Android.connectToGoogleFit
          ) {
            window.Android.connectToGoogleFit();
          } else if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.visitIosView
          ) {
            window.webkit.messageHandlers.visitIosView.postMessage(
              JSON.stringify({
                method: 'connectToGoogleFit',
              })
            );
          }
        }
      } else {
        if (window.Android !== undefined && window.Android.connectToGoogleFit) {
          window.Android.connectToGoogleFit();
        } else if (
          window.webkit &&
          window.webkit.messageHandlers &&
          window.webkit.messageHandlers.visitIosView
        ) {
          window.webkit.messageHandlers.visitIosView.postMessage(
            JSON.stringify({
              method: 'connectToGoogleFit',
            })
          );
        }
      }
    }
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const timeStamp = today.valueOf();
    this.getFitneesDetailsOfUser();
    getNutritionDetails(timeStamp, userId, authToken).then((res) => {
      console.log(res);
      this.setState({
        currentCals: res.totalToday,
        totalCals: res.remainingCals,
      });
    });
  }
  componentWillUnmount() {
    console.log('unmounted');
    window.removeEventListener('popstate', this.closeViewEvent, false);
  }

  closeViewEvent = (event) => {
    console.log('check now', this.props.location.state);
    if (event.state) {
      if (window.Android && window.Android.closeView) {
        window.Android.closeView();
      }
      console.log('window is refreshed');
    }
  };

  componentDidUpdate() {
    // window.addEventListener(
    //   'popstate',
    //   (event) => {
    //     console.log('Check this');
    //     if (window.Android && window.Android.closeView) {
    //       window.Android.closeView();
    //     }
    //     return false;
    //   },
    //   false
    // );
    // window.addEventListener('popstate', this.closeViewEvent, false);
    // if (window.location.href === 'file:///landing') {
    //   console.log('Check this');
    //   if (window.Android && window.Android.closeView) {
    //     window.Android.closeView();
    //   }
    //   return;
    // } else {
    //   window.history.pushState(
    //     { name: 'browserBack' },
    //     'on browser back click',
    //     window.location.href
    //   );
    // }
    // if (
    //   window.history &&
    //   window.history.state &&
    //   window.history.state.name === 'browserBack'
    // ) {
    //   console.log('Check this');
    //   if (window.Android && window.Android.closeView) {
    //     window.Android.closeView();
    //   }
    // }
    // window.addEventListener(
    //   'popstate',
    //   (event) => {
    //     if (event.state) {
    //       console.log('window is refreshed');
    //     }
    //   },
    //   false
    // );
  }

  callDashboardApi() {
    const { dispatch } = this.props;
    dashboardApi(this.props.authToken)
      .then((result) => {
        dispatch(setFitcoinsBalance(result.userInfo.walletBalance));
        result.home.forEach((categories) => {
          switch (categories.category) {
            case 'tinder-benefits':
              this.setState({
                tinderBenefits: categories,
              });
              break;
            case 'enrollment-card':
              this.setState({
                enrollmentCard: [categories],
              });
              break;
            case 'services':
              this.setState({
                servicesCards: categories.cards,
              });
              break;
            case 'fit-coins':
              this.setState({
                fitCoinsCards: categories.cards,
              });
              break;
            case 'opd-policy':
              this.setState({
                opdCards: categories.cards,
              });
              break;
            case 'invite-earn':
              // this.setState({
              //   inviteCards: categories.cards,
              // });
              break;
            default:
              break;
          }
        });
        this.setState({
          dashboardApiLoaded: true,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  homeTab = () => {
    homeTab(this.props.authToken)
      .then((res) => {
        const subscriptionCards = res.home.find(
          (ele) => ele.category === 'subscription'
        );
        if (subscriptionCards) {
          const ahcCard = subscriptionCards.cards.find(
            (ele) => ele.cardKey === 'lab-ahc-card'
          );
          if (
            ahcCard &&
            ahcCard.cardDirective &&
            ahcCard.cardDirective.action &&
            ahcCard.cardDirective.action.redirectTo === 'star-health-ahc'
          ) {
            this.setState({
              ahcCard: {
                ...ahcCard,
                cardType: 'ahc-card',
              },
            });
          }
        }
        // this.setState({
        //   ahcCard: {
        //     cardType: 'ahc-card',
        //     cardKey: 'lab-ahc-card',
        //     refresh: false,
        //     image:
        //       'https://visit-public.s3.ap-south-1.amazonaws.com/dashboard-images/star-health-ahc.png',
        //     cardDirective: {
        //       actionType: 'native',
        //       action: {
        //         redirectTo: 'star-health-ahc',
        //       },
        //       elements: [],
        //     },
        //   },
        // });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  startTreament = (
    showSearch,
    pendingConsult,
    cancelledRequest,
    showVerticals,
    verticalId
  ) => {
    const { history, authToken, userId } = this.props;
    if (cancelledRequest > 0) {
      history.push('/consultation/cancel-blocker', {
        requestId: cancelledRequest,
      });
    } else if (pendingConsult > 0) {
      getConsultationSummary(pendingConsult, userId, authToken)
        .then((summary) => {
          const { dateLabel, doctorId, doctorName, doctorImg } =
            summary.consultationInfo;
          this.setState({
            pendingConsult: {
              consultationId: pendingConsult,
              doctorId,
              doctorImg,
              doctorName,
              dateLabel,
            },
            showModal: true,
            showPendingConsultBlocker: true,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      history.push('/consultation/doctor-select', {
        specialistSearch: false,
        searchType: 'find',
        showSearch,
        showVerticals,
        verticalId: verticalId,
      });
    }
  };

  findSpecialist = (pendingConsult, cancelledRequest) => {
    const { history, authToken, userId } = this.props;

    if (this.state.blockers.cancelledRequest > 0) {
      this.props.history.push('/consultation/cancel-blocker', {
        requestId: this.state.blockers.cancelledRequest,
        goBack: true,
      });
    } else if (this.state.blockers.pendingRequestId > 0) {
      this.props.history.push('/consultation/pending-blocker', {
        requestId: this.state.blockers.pendingRequestId,
        goBack: true,
      });
    } else {
      history.push('/consultation/preview', {
        specialistSearch: false,
        searchType: 'find',
      });
    }
  };

  fitnessSyncReward = () => {
    fitnessSyncReward()
      .then((res) => {
        this.getCompletedRewardsAlert();
      })
      .catch((err) => {});
  };

  bookTests = () => {
    const { history } = this.props;
    history.push('/lab-test/select-policy');
  };

  orderMedicine = () => {
    const { history } = this.props;
    history.push('/pharmacy');
  };

  hideModal = () => {
    this.setState({ showModal: false });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  openRewards = () => {
    const { history } = this.props;
    history.push('/home/rewards');
  };

  showFitcoinModal = () => {
    this.setState({
      showModal: true,
      modalVisible: true,
    });
  };

  runAction(ele) {
    const { cardDirective } = ele;
    const { userId, history } = this.props;
    console.log(ele, cardDirective.action.redirectTo);
    switch (cardDirective.actionType) {
      case 'bot':
        history.push('/bot', {
          chatName: cardDirective.action.text,
          channel: `visit.bot.${userId}`,
          action: cardDirective.action,
        });
        break;
      case 'native':
        switch (cardDirective.action.redirectTo) {
          case 'meal-log':
            logEvent('Meal Tracking Dashboard Card Clicked');
            history.push('/logmeal', {
              from: 'normal',
            });
            break;
          case 'hra':
            logEvent('HRA Home Tab Card');
            history.push('/hra');
            break;
          case 'ahc-detail':
            history.push('/lab-test/ahc-package?showCloseButton=false');
            break;
          case 'star-health-ahc':
            history.push('/lab-test/star-health-ahc?showCloseButton=true');
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  }

  closeUploadModal = () => {
    this.setState({
      showPendingConsultBlocker: false,
    });
  };

  submitUploadModal = (consultationId) => {
    const { history } = this.props;
    history.push('/home', {
      consultationId,
    });
  };

  fetchConsultationBlocker = () => {
    fetchConsultationBlocker(this.props.authToken)
      .then((res) => {
        this.setState({
          blockers: res.blockers,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  getFitneesDetailsOfUser = async () => {
    const headers = {
      authorization: this.props.authToken,
    };

    try {
      this.setState({ dashboardApiLoaded: false });
      const response = await axios.get(
        `${config.apiBaseUrl}/console/home?v=180000717`,
        {
          headers,
        }
      );
      const resp = response.data;
      if (resp.message === 'success') {
        console.log(resp.home[0].cards[0].elements);
        this.setState({
          steps: resp.home[0].cards[0].elements[0],
          meals: resp.home[0].cards[0].elements[1],
          sleep: resp.home[0].cards[0].elements[2],
          dashboardApiLoaded: true,
        });
      } else {
        this.setState({ dashboardApiLoaded: true });
        const err = new Error('Invalid response');
        err.data = response;
        showError(err);
        throw err;
      }
    } catch (e) {
      this.setState({ dashboardApiLoaded: true });
      showError(e);
      throw e;
    }
  };

  closeCongratulateModal = () => {
    this.setState({
      congratulateModal: false,
    });
  };

  getConfig = () => {
    getConfig(this.props.authToken)
      .then((response) => {
        this.props.dispatch(setWellnessAndHraVisibility(response.rewardActive));
      })
      .catch((err) => console.log(err));
  };

  getCompletedRewardsAlert = () => {
    if (this.state.alertApiCalled) {
      return null;
    }
    this.setState({
      alertApiCalled: true,
    });
    getCompletedRewardsAlert(this.props.authToken)
      .then((response) => {
        console.log(response);
        this.props.dispatch(setWellnessBalance(response.walletBalance));
        if (response.result.length > 0) {
          this.setState(
            {
              rewardData: response.result[0],
            },
            () => {
              this.setState({ congratulateModal: true });
            }
          );
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    console.log(process.env.REACT_APP_FONT_MULTIPLIER);
    const { history, showFitCoins } = this.props;
    const {
      servicesCards,
      fitCoinsCards,
      opdCards,
      quickActionCards,
      hraCard,
      inviteCards,
      showModal,
      modalVisible,
      showPendingConsultBlocker,
      pendingConsult,
      tinderBenefits,
      enrollmentCard,
      ahcCard,
    } = this.state;
    const renderServicesCard = (ele, idx) => {
      console.log(ele);
      switch (ele.cardType) {
        case 'con-assistant':
          return (
            <HomeActionCard
              key={idx}
              onTap={() =>
                this.startTreament(
                  ele.cardDirective.action.allowSearch,
                  ele.pendingConsult,
                  ele.cancelledRequest,
                  ele.cardDirective.action.showVerticals,
                  ele.cardDirective.action.verticalId
                )
              }
              offer={ele.offer}
              title={ele.label}
              subtitle={ele.labelInfo}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              icon={onlineIcon}
            />
          );
        case 'con-search':
          return (
            <HomeActionCard
              key={idx}
              onTap={() =>
                this.findSpecialist(ele.pendingConsult, ele.cancelledRequest)
              }
              offer={ele.offer}
              title={ele.label}
              subtitle={ele.labelInfo}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              icon={offlineIcon}
            />
          );
        // case 'sym-check':
        //   return (
        //     <HomeActionCard
        //       key={idx}
        //       onTap={() => this.runAction(ele)}
        //       title={ele.label}
        //       subtitle={ele.labelInfo}
        //       earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
        //       icon={symCheckIcon}
        //     />
        //   );
        case 'hra-card':
          logEvent('HRA Home Tab Card');
          let hraCard;
          {
            this.props.showHra
              ? (hraCard = (
                  <HomeActionCard
                    key={idx}
                    onTap={() => this.runAction(ele)}
                    title="Health Risk Assessment"
                    subtitle="Get insights into your health"
                    icon={hraIcon}
                  />
                ))
              : (hraCard = '');
          }
          return hraCard;
        case 'ahc-card':
          return (
            <ImageCard
              onTap={() => this.runAction(ele)}
              imageLink={
                ele.image ||
                'https://visit-public.s3.ap-south-1.amazonaws.com/labs_packages/Banner_Health_Checkup.png'
              }
            />
          );
        case 'support':
          return (
            <div
              className="support-card mb-5 mx-8px hover"
              onClick={this.openSupport}
              key={ele.cardType}
            >
              <div className="flex-center">
                <img src={ChatIcon} alt="chat" />
                <p className="ml-5">{ele.label}</p>
              </div>
              <ArrowIcon />
            </div>
          );
        default:
          return false;
      }
    };

    const renderActionsCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'med-del':
          if (ele.showPharmacy) {
            return (
              <HomeActionCard
                onTap={() => this.orderMedicine(ele)}
                title={ele.label}
                icon={medIcon}
                offer={ele.offer}
                discountText={ele.labelBanner}
                earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
              />
            );
          }
          return;
        case 'lab-tests':
          return (
            <HomeActionCard
              onTap={this.bookTests}
              title={ele.label}
              icon={labIcon}
              offer={ele.offer}
              discountText={ele.labelBanner}
              earnBadge={ele.ftLabel && ele.ftLabel.trim() !== ''}
            />
          );
        default:
          return false;
      }
    };
    const renderQuickActionCard = (ele) => {
      switch (ele.cardType) {
        case 'meal-log':
          return (
            <div style={{ width: '100%' }}>
              <HomeActionCard
                key={ele.cardType}
                onTap={() => this.runAction(ele)}
                title={ele.label}
                icon={mealIcon}
                discountText={ele.labelBanner}
                earnBadge={showFitCoins}
              />
            </div>
          );
        case 'metabolic-profile':
          return (
            <HomeActionCard
              key={ele.cardType}
              onTap={() => this.runAction(ele)}
              title={ele.label}
              icon={metaIcon}
              discountText={ele.labelBanner}
              earnBadge={showFitCoins}
            />
          );
        // case 'water-log':
        //   return <WaterLogCard />;
        default:
          return false;
      }
    };
    const renderOpdCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'buy-policy':
          return; // <HomeActionCard
        //   key={ele.cardType}
        //   onTap={() => history.push('/reimbursement/fulfillment-details')}
        //   title={'OPD Reimbursement'}
        //   icon={metaIcon}
        // />
        case 'activate-opd':
          return <PolicyActivationCard onTap={() => {}} />;
        default:
          return false;
      }
    };
    const renderInviteCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'ftInvite':
          return <ReferCard key={idx} onTap={() => history.push('/refer')} />;
        default:
          return false;
      }
    };
    const renderFitcoinCard = (ele, idx) => {
      switch (ele.cardType) {
        case 'claim-coins':
          return (
            <RewardsCard
              key={idx}
              onSeeAllTapped={this.openRewards}
              onKnowMoreTapped={this.showFitcoinModal}
            />
          );
        default:
          return false;
      }
    };
    const renderEnrollmentCard = (ele, idx) => {
      console.log(ele);
      return (
        <div
          className="border border-txtsecondary-shades7 m-8px bg-textbox rounded-8 cursor-pointer"
          onClick={() => {
            window.location.assign(
              ele.cards[0].cardDirective.action.redirectTo
            );
          }}
        >
          <div className="p-20px flex flex-row justify-between items-center">
            <div className="mr-16px">
              <p className="text-body-l font-semibold text-txtlight mb-12px">
                {ele.title}
              </p>
              <p className="text-body-s font-medium text-txtlight">
                {ele.text}
              </p>
            </div>
            <img
              src={ele.image}
              alt="enroll"
              style={{
                width: '30%',
              }}
            />
          </div>
          <div className="border-t border-txtsecondary-shades7 p-20px flex flex-row items-center justify-center">
            <img
              src={membersIcon}
              alt="enroll"
              style={{
                height: '20px',
              }}
            />
            <p className="text-body-l font-semibold text-primary ml-8px">
              {ele.buttonText}
            </p>
          </div>
        </div>
      );
    };
    return (
      <FadeInTransition
        in={true}
        timeout={200}
        mountOnEnter
        unmountOnExit
        appear
      >
        <>
          {this.state.dashboardApiLoaded ? (
            <BodyContainer>
              {enrollmentCard && enrollmentCard.length > 0
                ? enrollmentCard.map(renderEnrollmentCard)
                : ''}
              {/* {tinderBenefits ? (
                <TinderBenefitsCard
                  benefits={tinderBenefits}
                  history={this.props.history}
                />
              ) : (
                ''
              )} */}
              {opdCards.map(renderOpdCard)}

              {window.Android ||
              (window.webkit && window.webkit.messageHandlers) ||
              window.ReactNativeWebView ||
              window.wkWebView ? (
                this.props.googleFitnessPermission ? (
                  <GoalProgressCard
                    history={history}
                    authToken={this.props.authToken}
                    steps={this.state.steps}
                    meals={this.state.meals}
                    sleep={this.state.sleep}
                    currentSteps={this.props.currentSteps}
                    currentSleep={this.props.currentSleep}
                    currentCals={this.state.currentCals}
                    totalCals={this.state.totalCals}
                    authToken={this.props.authToken}
                  />
                ) : (
                  <ActivityTracker user={this.props.user} />
                )
              ) : (
                ''
              )}
              {/* {ahcCard && [ahcCard].map(renderServicesCard)} */}
              {servicesCards.map(renderServicesCard)}
              {!tinderBenefits ? hraCard.map(renderServicesCard) : ''}
              {servicesCards.map(renderActionsCard)}
              <WaterLogCard
                authToken={this.props.authToken}
                history={history}
              />
              {(!window.Android ||
                (!window.webkit && !window.webkit.messageHandlers) ||
                !window.ReactNativeWebView ||
                !window.wkWebView) && (
                <QuickActionsContainer>
                  {quickActionCards.map(renderQuickActionCard)}
                </QuickActionsContainer>
              )}

              {/* {fitCoinsCards.map(renderFitcoinCard)} */}
              {inviteCards.map(renderInviteCard)}
            </BodyContainer>
          ) : (
            <LoadingComponent />
          )}
          {showModal && (
            <Modal>
              <ModalContainer>
                <FlyInTransition
                  in={modalVisible}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <ModalBody>
                    <CloseContainer onTap={this.closeModal}>
                      <CloseIcon />
                    </CloseContainer>
                    <GiftImage src={giftIcon} />
                    <LineSeparator src={fitcoinLine} />
                    <ModalTitle>Introducing FIT Coins</ModalTitle>
                    <StepsContainer>
                      <StepContainer>
                        <StepNumber>1</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Complete transactions</StepTextTile>
                          <StepTextSubtitle>
                            Book consultations, order medicines & Lab tests via
                            VISIT App
                          </StepTextSubtitle>
                        </StepTextContainer>
                      </StepContainer>
                      <StepContainer>
                        <StepNumber>2</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Earn Reward Points</StepTextTile>
                          <StepTextSubtitle>
                            For every successful transaction, you earn upto 1000
                            FITCoins
                          </StepTextSubtitle>
                          <StepTextSubtitleContainer>
                            <StepTextSubtitle>
                              Spending ₹ 1 = 1{' '}
                            </StepTextSubtitle>
                            <FitcoinIcon src={fitcoinIconWhite} />
                          </StepTextSubtitleContainer>
                        </StepTextContainer>
                      </StepContainer>
                      <StepContainer>
                        <StepNumber>3</StepNumber>
                        <StepTextContainer>
                          <StepTextTile>Claim your rewards</StepTextTile>
                          <StepTextSubtitle>
                            Spend your FITcoins to claim vouchers, Phonepe Cash
                            and more offers.
                          </StepTextSubtitle>
                        </StepTextContainer>
                      </StepContainer>
                    </StepsContainer>
                    <FitcoinButton onTap={this.openRewards}>
                      <ButtonText>SEE REWARDS</ButtonText>
                    </FitcoinButton>
                  </ModalBody>
                </FlyInTransition>
                <FlyInTransition
                  in={showPendingConsultBlocker}
                  mountOnEnter
                  unmountOnExit
                  onExited={this.hideModal}
                  appear
                >
                  <UploadPrescription
                    doctorId={pendingConsult.doctorId}
                    consultationId={pendingConsult.consultationId}
                    doctorImg={pendingConsult.doctorImg}
                    doctorName={pendingConsult.doctorName}
                    dateLabel={pendingConsult.dateLabel}
                    onClose={this.closeUploadModal}
                    onSubmit={this.submitUploadModal}
                  />
                </FlyInTransition>
              </ModalContainer>
            </Modal>
          )}
          {this.state.congratulateModal && (
            <Modal>
              <CongratulateContainer>
                <CongratulationModal
                  closeModal={this.closeCongratulateModal}
                  rewardData={this.state.rewardData}
                />
              </CongratulateContainer>
            </Modal>
          )}
        </>
      </FadeInTransition>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  userId: state.user.userId,
  user: state.user,
  showFitCoins: state.app.config.showFitCoins,
  showHra: state.app.showWellnessAndHra,
  googleFitnessPermission: state.app.googleFitnessPermission,
  currentSteps: state.app.currentSteps,
  currentSleep: state.app.currentSleep,
});

export default connect(mapStateToProps)(HomeContainer);
