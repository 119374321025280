import React, { Component } from 'react';
import styled from 'styled-components';
import GoogleFitnessLogo from '../../images/common/google-fitness-logo.png';

const OuterContainer = styled.div`
  margin: 0.4rem 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(165, 165, 165, 0.1);
  border-radius: 8px;
`;

const ActivityTrackingContainer = styled.div`
  background: #ffffff;
  border-radius: 8px;
  padding: 18px;
`;

const Header = styled.div`
  width: 100%;
`;

const HeaderName = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 150%;
  display: inline-block;
  color: #21243c;
`;

const HeaderTag = styled.p`
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.25px;
  color: #585969;
`;

const Inspect = styled.p`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  margin-left: 4px;
  background: #f0f3f6;
  border-radius: 50%;
  cursor: pointer;
  color: #3f8cff;
  float: right;
`;

const Tick = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background: #6543c6;
  border-radius: 62px;
  color: #ffffff;
  margin-right: 4px;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: Manrope;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #21243c;
  margin-top: 6px;
  margin-bottom: 6px;
`;

const Button = styled.button`
  width: 100%;
  padding: 10px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  color: #916ef7;
  margin-top: 18px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  border-top: 1px solid rgba(165, 165, 165, 0.1);
`;

const FitnessLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

export class ActivityTracker extends Component {
  connectToFitness = () => {
    console.log('Connecting to Android SDK');
    if (window.Android !== undefined && window.Android.connectToGoogleFit) {
      window.Android.connectToGoogleFit();
    } else if (window.webkit && window.webkit.messageHandlers.visitIosView) {
      window.webkit.messageHandlers.visitIosView.postMessage(
        JSON.stringify({
          method: 'connectToGoogleFit',
        })
      );
    }
  };

  render() {
    const { user } = this.props;
    return (
      <OuterContainer>
        <ActivityTrackingContainer>
          <Header>
            <HeaderName>Hi {user.userName}!</HeaderName>
            {/* <Inspect>i</Inspect> */}
          </Header>
          <HeaderTag>Sync your steps today</HeaderTag>
          <InfoContainer>
            <Tick>✔</Tick>
            Track your activity
          </InfoContainer>
          <InfoContainer>
            <Tick>✔</Tick>
            Stay fit
          </InfoContainer>
          <InfoContainer>
            <Tick>✔</Tick>
            Earn Reward Points
          </InfoContainer>
          <Button onClick={this.connectToFitness}>
            <FitnessLogo src={GoogleFitnessLogo} width="30px" height="30px" />
            Connect to Google Fit
          </Button>
        </ActivityTrackingContainer>
      </OuterContainer>
    );
  }
}

export default ActivityTracker;
