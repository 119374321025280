import React, { Component } from 'react';
import {
  checkIdAndGetMagicLink,
  checkTokenValidity,
} from '../../services/star-health';
import moment from 'moment';
import Loader from '../../images/common/star2.gif';
import { loginMagicLink, updateLoginState } from '../../actions';
import { connect } from 'react-redux';
import config from '../../config';
class StarHealth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      id: null,
      token: null,
    };
    const { location } = this.props;
    if (location.search) {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');
      const id = params.get('id');
      console.log(id);
      // if (window.innerWidth <= 1040) {
      //   window.open(
      //     `${config.starRedirection}/star-health?token=${token}&id=${id}`,
      //     '_self'
      //   );
      // }
      if (token && id) {
        this.state.token = token;
        this.state.id = id;
      }
    }
  }

  componentDidMount() {
    const body = {
      token: this.state.token,
      sId: this.state.id,
    };
    this.checkIdAndGetLink(body);
  }

  // checkTokenValidty() {
  //   const { dispatch } = this.props;
  //   const body = {
  //     token: this.state.token,
  //     sId: this.state.id,
  //   };
  //   return checkTokenValidity(body)
  //     .then((res) => {
  //       this.setState({ loading: false });
  //       if (res.status === 'success') {
  //         const body = {
  //           token: this.state.token,
  //           sId: this.state.id,
  //         };
  //         this.checkIdAndGetLink(body);
  //       } else {
  //         dispatch(updateLoginState('LOGGED_OUT'));
  //         dispatch({
  //           type: 'UNSET_USER_INFO',
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       dispatch(updateLoginState('LOGGED_OUT'));
  //       dispatch({
  //         type: 'UNSET_USER_INFO',
  //       });
  //     });
  // }

  checkIdAndGetLink(body) {
    const { history, dispatch } = this.props;
    checkIdAndGetMagicLink(body)
      .then((res) => {
        this.setState({ loading: false });
        console.log(res.result);
        if (res.magicCode) {
          dispatch(updateLoginState('LOGGED_OUT'));
          dispatch({
            type: 'UNSET_USER_INFO',
          });
          dispatch(loginMagicLink(res.magicCode, history));
        } else {
          window.location.assign(res.result);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  render() {
    return (
      <>
        <div className="w-full flex center-align justify-center">
          {this.state.loading && (
            <img src={Loader} alt="...loading" width="70px" height="70px" />
          )}
        </div>
      </>
    );
  }
}

export default connect()(StarHealth);
