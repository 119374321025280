import React, { Component } from 'react';
import styled from 'styled-components';

const ModalBoundary = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  color: #ffffff;
`;

const InnerBoundary = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #3a5c81;
  border-radius: 8px 8px 0px 0px;
  padding: 18px;
`;

const RewardDataContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e3e3;
`;

const TableDiv = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
`;

const LeftElement = styled.p`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #404040;
`;

const RightElement = styled.p`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #404040;
  word-wrap: break-word;
  text-align: center;
  padding-left: 3px;
`;

export default class WellnessInfoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <ModalBoundary>
        <HeaderContainer>
          <div
            className="p-16px pl-20px 
              border-txtsecondary-shades4 flex flex-row items-center
              "
          >
            <Title>Star Wellness Program</Title>
          </div>
        </HeaderContainer>
        <InnerBoundary>
          <RewardDataContainer>
            <TableDiv
              style={{
                textAlign: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid black',
              }}
            >
              <LeftElement
                style={{
                  fontWeight: 'bolder',
                  marginRight: '4px',
                  textAlign: 'center',
                }}
              >
                Wellness Points Earned
              </LeftElement>
              <RightElement style={{ fontWeight: 'bolder' }}>
                Discount in Premium
              </RightElement>
            </TableDiv>
            <TableDiv>
              <LeftElement>200 to 350</LeftElement>
              <RightElement>2%</RightElement>
            </TableDiv>
            <TableDiv>
              <LeftElement>351 to 600</LeftElement>
              <RightElement>5%</RightElement>
            </TableDiv>
            <TableDiv>
              <LeftElement>600 to 750</LeftElement>
              <RightElement>7%</RightElement>
            </TableDiv>
            <TableDiv>
              <LeftElement>751 to 1000</LeftElement>
              <RightElement>10%</RightElement>
            </TableDiv>
          </RewardDataContainer>
        </InnerBoundary>
      </ModalBoundary>
    );
  }
}
