import axios from 'axios';
import config from '../config';
import { showError } from '../utils';

import { store } from '../store';

export function fetchClaims(relative = 'all', authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/care/claims?relativeId=${relative}`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchClaimSummary(claimId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/reimbursements/${claimId}/claim-status`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function fetchClaimSummaryV2(claimId, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .get(`${config.apiBaseUrl}/reimbursements/v2/${claimId}/claim-status`, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}

export function uploadDocuments(body, authToken) {
  const headers = {
    authorization: authToken,
  };

  return axios
    .post(`${config.apiBaseUrl}/reimbursements/v2/upload-documents`, body, {
      headers,
    })
    .then((resp) => {
      const responseBody = resp.data;
      if (responseBody.message === 'success') {
        return responseBody;
      } else {
        const err = new Error('Invalid response');
        err.data = responseBody;
        showError(err);
        throw err;
      }
    })
    .catch((err) => {
      showError(err);
      throw err;
    });
}
