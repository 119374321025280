import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import StyledTappable from '../common/StyledTappable';
import { EarnBadge } from '../common/Fitcoin';
import { Text } from '../common/Text';

const OuterContainer = styled(StyledTappable)`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  margin: 0 6px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  background: white;
  z-index: 999;
  border-radius: 8px;
  padding: 12px 0;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.05);
`;

const TitleIcon = styled.img`
  width: 50px;
  height: 50px;
`;

const TitleText = styled(Text)`
  color: #050505;
  margin: 0 8px;
  margin-top: 20px;
  font-size: 16px;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: stretch;
  transform: translateY(-10px);
  box-shadow: 0 0 1px 0 rbga(0, 0, 0, 0.1);
`;

const StyledEarnBadge = styled(EarnBadge)`
  border-radius: 0 0 6px 6px;
  flex: 1 0;
  justify-content: center;
  padding-top: 12px;
`;

const QuickActionCard = ({ className, onTap, title, icon, earnBadge }) => (
  <OuterContainer className={className} onTap={onTap}>
    <TitleContainer>
      <TitleIcon src={icon} alt={title} />
      <TitleText>{title}</TitleText>
    </TitleContainer>
    <BadgeContainer>{earnBadge && <StyledEarnBadge />}</BadgeContainer>
  </OuterContainer>
);

QuickActionCard.propTypes = {
  onTap: PropTypes.func,
  discountText: PropTypes.string,
  earnBadge: PropTypes.bool,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

QuickActionCard.defaultProps = {
  discountText: null,
  earnBadge: false,
};

export default QuickActionCard;
