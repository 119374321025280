import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import arrowLeft from '../../images/common/arrow-left.svg';
import discountIcon from '../../images/common/discount.svg';
import checkedIcon from '../../images/pharmacy/checked.svg';
import PaymentSuccessModal from '../buying-journey/PaymentSuccess';
import SubmitConfirmModal from '../buying-journey/SubmitConfirm';
import Modal from '../../containers/common/Modal';
import { FlyInTransition } from '../../components/transitions/transitions';
import supportIcon from '../../images/common/support1.svg';
import infoIcon from '../../images/buying-journey/info.svg';
import successIcon from '../../images/buying-journey/success.svg';
import membersIcon from '../../images/buying-journey/members.svg';
import detailsIcon from '../../images/buying-journey/details.svg';
import calendarIcon from '../../images/buying-journey/calendar.svg';
import crossIcon from '../../images/common/cross.svg';
import greenInfoIcon from '../../images/buying-journey/green-info.svg';
import maleIcon from '../../images/buying-journey/male.png';
import femaleIcon from '../../images/buying-journey/female.png';
import sonIcon from '../../images/buying-journey/son.png';
import daughterIcon from '../../images/buying-journey/daughter.png';
import fatherIcon from '../../images/buying-journey/father.png';
import motherIcon from '../../images/buying-journey/mother.png';
import config from '../../config';
import {
  addRelative,
  getOrderSummary,
  addDependentsToPlan,
} from '../../services/buying-journey';
import initializeFreshDesk from '../../utils/freshchat';
import { initializeAmplitudeClient } from '../../services/analytics';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { logEvent } from '../../utils/logEvent';

class AddMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: true,
      name: '',
      dobDate: '',
      dobMonth: '',
      dobYear: '',
      mobileNumber: '',
      gender: '',
      relation: '',
      showRelations: false,
    };
    this.dateNodeRef = React.createRef();
    this.monthNodeRef = React.createRef();
    this.yearNodeRef = React.createRef();
    this.mobileNodeRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { showModal } = this.props;
    if (prevProps.showModal && !showModal) {
      this.hideModal();
    }
    if (!prevProps.showModal && showModal) {
      this.setState({
        showModal: true,
      });
    }
  }

  hideModal = () => {
    this.setState({
      name: '',
      dobDate: '',
      dobMonth: '',
      dobYear: '',
      gender: '',
      relation: '',
      mobileNumber: '',
    });
    this.setState({ showModal: false });
    setTimeout(this.props.hideModal, 200);
  };

  handleOutsideClick = (e) => {
    if (this.modalNode && this.modalNode.contains(e.target)) {
      if (this.relationNode.contains(e.target)) {
        return;
      } else {
        this.setState({
          showRelations: false,
        });
        return;
      }
    }
    this.hideModal();
  };

  addRelative = () => {
    const { name, dobDate, dobMonth, dobYear, gender, mobileNumber, relation } =
      this.state;
    this.props.addRelative({
      name,
      dateOfBirth: `${dobDate}-${dobMonth}-${dobYear}`,
      mobileNumber,
      relation,
      gender,
    });
  };

  render() {
    return (
      <Modal>
        <div className="modalContainer" onClick={this.handleOutsideClick}>
          <FlyInTransition
            in={this.state.showModal}
            mountOnEnter
            unmountOnExit
            appear
          >
            <div
              className="bg-textbox absolute bottom-0px w-full rounded-t-3xl max-h-full overflow-auto"
              ref={(node) => (this.modalNode = node)}
            >
              <div className="p-20 border-b border-txtsecondary-shades7 border-solid flex flex-row items-center justify-between">
                <div className="flex flex-row items-center">
                  <img src={membersIcon} alt="home" />
                  <span className="text-title-s ml-10px font-semibold text-txtlight">
                    {this.props.relation} Details
                  </span>
                </div>
                <img src={crossIcon} alt="cross" onClick={this.hideModal} />
              </div>
              <div className="p-20 max-h-full overflow-auto">
                <div>
                  <p className="text-body-s text-txtlight font-medium">
                    Full Name
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Enter name here"
                    value={this.state.name}
                    onChange={(e) => {
                      this.setState({
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Date of Birth
                  </p>
                  <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-1">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="dd"
                        type="number"
                        value={this.state.dobDate}
                        maxLength="2"
                        ref={this.dateNodeRef}
                        onChange={(e) => {
                          if (e.target.value.length === 2) {
                            this.monthNodeRef.current &&
                              this.monthNodeRef.current.focus();
                          }
                          this.setState({
                            dobDate: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-1">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="mm"
                        maxLength="2"
                        type="number"
                        ref={this.monthNodeRef}
                        value={this.state.dobMonth}
                        onChange={(e) => {
                          if (e.target.value.length === 2) {
                            this.yearNodeRef.current &&
                              this.yearNodeRef.current.focus();
                          }
                          this.setState({
                            dobMonth: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="col-span-2">
                      <input
                        className="mt-8px new-address-input text-title-s font-semibold text-center"
                        placeholder="yyyy"
                        type="number"
                        maxLength="4"
                        ref={this.yearNodeRef}
                        value={this.state.dobYear}
                        onChange={(e) => {
                          if (e.target.value.length === 4) {
                            this.mobileNodeRef.current &&
                              this.mobileNodeRef.current.focus();
                          }
                          this.setState({
                            dobYear: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Mobile Number (Optional)
                  </p>
                  <input
                    className="mt-8px new-address-input text-title-s font-semibold"
                    placeholder="Your 10 digit valid number"
                    type="number"
                    value={this.state.mobileNumber}
                    ref={this.mobileNodeRef}
                    onChange={(e) => {
                      this.setState({
                        mobileNumber: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="mt-24px">
                  <p className="text-body-s text-txtlight font-medium">
                    Select Relation
                  </p>
                  <div
                    className="new-address-input"
                    ref={(node) => (this.relationNode = node)}
                    style={
                      this.state.showRelations
                        ? { marginTop: '10px', borderColor: '#013ADE' }
                        : { marginTop: '10px' }
                    }
                    onClick={() => {
                      this.setState({
                        showRelations: !this.state.showRelations,
                      });
                    }}
                  >
                    {this.state.relation ? (
                      <span className="text-title-s font-semibold">
                        {' '}
                        {this.state.relation}{' '}
                      </span>
                    ) : (
                      <span className="txt-grey-500-12">Select Relation</span>
                    )}
                    <span className="float-right">
                      {this.state.showRelations ? (
                        <FaAngleUp />
                      ) : (
                        <FaAngleDown />
                      )}
                    </span>
                    {this.state.showRelations && (
                      <div className="custom-select mt-10">
                        {this.props.relations.map((ele) => {
                          return (
                            <span
                              className="custom-select-option text-body-s"
                              style={{
                                fontSize: '12px',
                              }}
                              onClick={() => {
                                this.setState({
                                  relation: ele.title,
                                  showRelations: false,
                                  gender: ele.gender,
                                });
                              }}
                            >
                              {ele.title}
                            </span>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.state.name.length > 0 &&
                this.state.gender &&
                this.state.relation &&
                this.state.dobDate.length > 0 &&
                this.state.dobMonth.length > 0 &&
                this.state.dobYear.length > 0 && (
                  <div
                    className="pt-20px pl-20px pr-20px pb-32px w-full"
                    style={{
                      background:
                        'linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 31.77%, #FFFFFF 66.15%, rgba(255, 255, 255, 0) 100%)',
                    }}
                  >
                    <div
                      className="bg-primary rounded-12 pt-12px pb-10px center"
                      onClick={this.addRelative}
                    >
                      {!this.props.adding ? (
                        <>
                          <span className="text-button-s font-bold text-textbox mr-10px">
                            Add
                          </span>
                        </>
                      ) : (
                        <span className="text-button-s font-bold text-textbox mr-10px">
                          Loading....
                        </span>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </FlyInTransition>
        </div>
      </Modal>
    );
  }
}

export class CovidOrderSummary extends Component {
  state = {
    orderInfo: {},
    memberInfo: [],
    relations: [],
    purchaseSuccess: false,
    showAddMemberModal: false,
    addingRelation: '',
    addingRelationGender: '',
    transactionId: null,
    dependentIds: [],
    coveredRelations: null,
    availableRelatives: [],
    maxDependents: null,
    submitConfirm: false,
  };

  componentDidMount() {
    const { history, location } = this.props;
    this.amplitudeClient = initializeAmplitudeClient();
    this.amplitudeClient.init();
    logEvent('Covid Home Care Order Completion Screen');
    if (location.search) {
      const params = new URLSearchParams(location.search);
      if (params.get('success') && params.get('success') === 'true') {
        const transactionId = params.get('transactionId');
        this.setState({
          transactionId,
        });
        this.getOrderSummary(transactionId);
        this.setState(
          {
            purchaseSuccess: true,
          },
          () => {
            setTimeout(() => {
              this.setState({
                purchaseSuccess: false,
              });
            }, 2000);
          }
        );
      } else {
        history.replace('/covid-home');
      }
    } else {
      history.replace('/covid-home');
    }
  }

  getOrderSummary = (transactionId) => {
    getOrderSummary(transactionId || this.state.transactionId)
      .then((res) => {
        this.setState({
          orderInfo: res.orderInfo,
          memberInfo: res.memberInfo,
          coveredRelations: res.coveredRelations,
          availableRelatives: res.availableRelatives,
          maxDependents: res.maxDependents,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  hideModal = () => {
    this.setState({
      purchaseSuccess: false,
      showAddMemberModal: false,
    });
  };

  openSupport = () => {
    initializeFreshDesk(this.props.user);
  };

  redirectToPaymentPage = () => {
    const { authToken } = this.props;
    const { autoDebit, selectedPlan } = this.state;
    window.location.href = `${config.apiBaseUrl}/subscription/transact?amount=${
      selectedPlan.cost
    }&subPlanTermId=${selectedPlan.subTermId}&autoDebit=${
      autoDebit ? 1 : 0
    }&auth=${authToken.split(' ')[1]}`;
  };

  addRelative = (relativeDetails) => {
    const { transactionId } = this.state;
    addRelative(relativeDetails, transactionId)
      .then((res) => {
        this.hideModal();
        this.addDependent(res.result.dependentId);
        this.getOrderSummary();
        logEvent('Covid Home Care Relative Saved');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  showAddRelativeModal = () => () => {
    logEvent('Covid Home Care Add Relative');
    this.setState({
      showAddMemberModal: true,
    });
  };

  showFinalConfirmation = () => {
    logEvent('Covid Home Care Submission Complete');
    addDependentsToPlan(this.state.dependentIds, this.state.transactionId)
      .then((res) => {
        this.setState(
          {
            submitConfirm: true,
          },
          () => {
            setTimeout(() => {
              if (typeof window.Android !== 'undefined') {
                return window.Android.reloadApp();
              } else if (window.ReactNativeWebView) {
                window.ReactNativeWebView.postMessage(
                  JSON.stringify({
                    method: 'reload',
                  })
                );
              } else {
                this.props.history.push('/buying-journey/success');
              }
            }, 4000);
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  addDependent = (dependentId) => {
    logEvent('Covid Home Care Relative Selected');

    const dependentIds = this.state.dependentIds.filter((ele) => {
      return ele !== dependentId;
    });
    dependentIds.push(dependentId);
    this.setState({
      dependentIds,
    });
  };
  removeDependent = (dependentId) => {
    const dependentIds = this.state.dependentIds.filter((ele) => {
      return ele !== dependentId;
    });
    this.setState({
      dependentIds,
    });
  };

  render() {
    const {
      selectedPlan,
      name,
      plans,
      autoDebit,
      purchaseSuccess,
      memberInfo,
      relations,
      orderInfo,
      availableRelatives,
      maxDependents,
      dependentIds,
      coveredRelations,
    } = this.state;
    const selfInfo = memberInfo.find((ele) => ele.relation === 'Self');
    const maxMembersAdded = dependentIds.length >= maxDependents;
    const getRelationIcon = (relation, gender) => {
      switch (relation) {
        case 'Self':
          if (gender && gender.toLowerCase() === 'male') {
            return maleIcon;
          } else {
            return femaleIcon;
          }
        case 'Spouse':
          if (gender && gender.toLowerCase() === 'male') {
            return maleIcon;
          } else {
            return femaleIcon;
          }
        case 'Child':
          if (gender && gender.toLowerCase() === 'male') {
            return sonIcon;
          } else {
            return daughterIcon;
          }
        case 'Father':
          return fatherIcon;
        case 'Mother':
          return motherIcon;
        default:
          return femaleIcon;
      }
    };
    const renderAddMemberButton = () => {
      return (
        <div
          onClick={this.showAddRelativeModal()}
          className="cursor-pointer p-16px mb-16px flex items-center justify-between w-100 rounded-xl shadow-card border border-txtsecondary-shades4"
        >
          <div className="flex items-center">
            <p className="text-body-l text-txtlight font-semibold">
              Add new dependent
            </p>
          </div>
          <div className="flex items-center">
            <img src={detailsIcon} alt="Add" />
            <p className="text-caption-l text-primary font-semibold">
              Add Details
            </p>
          </div>
        </div>
      );
    };
    const renderMemberDetails = (ele, idx) => {
      if (ele.name) {
        const age = moment().diff(moment(ele.dateOfBirth), 'years');
        return (
          <div
            key={idx}
            className="p-16px mb-16px w-100 rounded-12 shadow-card border border-txtsecondary-shades4 flex flex-row items-start"
          >
            <img
              className="w-32px mr-8px"
              src={getRelationIcon(ele.relation, ele.gender)}
              alt={ele.relation}
            />
            <div className="flex flex-col">
              <p className="text-chip text-txtsecondary-shades8 font-semibold mb-4px">
                {ele.relation.toUpperCase()}
              </p>
              <p className="text-body-s text-brochure font-semibold">
                {ele.name}, <span className="font-medium">{age}</span>
              </p>
              <p className="text-body-s text-txtsecondary-shades8 font-bold">
                {!ele.phone || ele.phone === 0
                  ? 'No phone number added'
                  : ele.phone}
              </p>
            </div>
          </div>
        );
      } else if (ele.dependentName) {
        const age = moment().diff(
          moment(ele.dependentDob, 'DD/MM/YYYY'),
          'years'
        );
        return (
          <div
            key={idx}
            className="p-16px mb-16px w-100 rounded-12 shadow-card border border-txtsecondary-shades4 flex flex-row items-start"
            onClick={() => {
              if (!dependentIds.includes(ele.dependentId)) {
                this.addDependent(ele.dependentId);
              } else {
                this.removeDependent(ele.dependentId);
              }
            }}
          >
            {!dependentIds.includes(ele.dependentId) && maxMembersAdded ? (
              ''
            ) : (
              <span
                className={
                  dependentIds.includes(ele.dependentId)
                    ? 'bg-primary self-center'
                    : 'bg-txtsecondary-shades4 self-center'
                }
                style={{
                  height: '18px',
                  width: '18px',
                  borderRadius: '6px',
                  marginRight: '12px',
                }}
              ></span>
            )}

            <img
              className="w-32px mr-8px"
              src={getRelationIcon(ele.dependentRelation, ele.dependentGender)}
              alt={ele.dependentRelation}
            />
            <div className="flex flex-col">
              <p className="text-chip text-txtsecondary-shades8 font-semibold mb-4px">
                {ele.dependentRelation.toUpperCase()}
              </p>
              <p className="text-body-s text-brochure font-semibold">
                {ele.dependentName}, <span className="font-medium">{age}</span>
              </p>
              <p className="text-body-s text-txtsecondary-shades8 font-bold">
                {!ele.dependentMobile || ele.dependentMobile === 0
                  ? 'No Mobile number added'
                  : ele.dependentMobile}
              </p>
            </div>
          </div>
        );
      } else {
      }
    };
    return (
      <div className="w-full overflow-auto">
        <div className="header p-16px pt-0 mt-52px border-b border-txtsecondary-shades4 flex flex-row justify-between">
          <p className="text-title-m text-txtlight font-semibold">
            Order Summary
          </p>
        </div>
        <div className="body pt-24px pl-20px pr-20px pb-200px">
          <div className="bg-green-shades4 rounded-xl mb-32px bg-opacity-10 flex items-center p-16px">
            <img className="mr-8px" src={successIcon} alt="Success" />
            <p className="text-body-s text-txtlight font-semibold">
              Plan Purchased Successfully!
            </p>
          </div>
          <div className="flex items-center mb-16px">
            <img className="mr-8px" src={membersIcon} alt="Members" />
            <p className="text-body-s text-txtlight font-semibold">
              Covered Members
            </p>
          </div>
          {memberInfo.map(renderMemberDetails)}

          {maxDependents > 0 ? (
            <>
              <div className="mb-16px">
                <div className="flex items-center">
                  <img className="mr-8px" src={membersIcon} alt="Members" />
                  <p className="text-body-s text-txtlight font-semibold">
                    ADD DEPENDENTS TO PLAN
                  </p>
                  <br />
                </div>
                <p className="text-body-s font-medium text-secondary-shades6">
                  You can add upto {maxDependents} dependents to this plan
                </p>
              </div>
              {availableRelatives.map(renderMemberDetails)}
              {maxMembersAdded > 0 ? '' : renderAddMemberButton()}
            </>
          ) : (
            ''
          )}
          {/* {memberInfo.map(renderMemberDetails)}
          {relations.map(renderAddRelativeButtons)}
          {renderAdditionalAddRelativeButtons()} */}
          {orderInfo && (
            <div className="pt-16px">
              <div className="flex items-center mb-20px">
                <img className="mr-8px" src={infoIcon} alt="Members" />
                <p className="text-body-s text-txtlight font-semibold">
                  Order Info
                </p>
              </div>
              <div className="border border-txtsecondary-shades4 rounded-xl px-20px mb-4px">
                <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                  <p className="text-caption-l text-txtlight font-medium">
                    Plan Charges
                  </p>
                  <p className="text-body-s text-txtlight font-semibold">
                    ₹ {orderInfo.coverCost}
                  </p>
                </div>
                <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                  <p className="text-caption-l text-txtlight font-medium">
                    GST
                  </p>
                  <p className="text-body-s text-txtlight font-semibold">
                    ₹ {orderInfo.gst}
                  </p>
                </div>
                <div className="py-12px flex items-center justify-between border-b border-txtsecondary-shades4">
                  <p className="text-body-l font-medium">Total Paid</p>
                  <p className="text-body-l text-txtlight font-semibold">
                    ₹ {orderInfo.coverCostInclGst}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
        {maxMembersAdded && (
          <div
            className="footer absolute bottom-0 w-full p-24px bg-textbox"
            style={{ boxShadow: '0px -1px 20px rgba(0, 0, 0, 0.1)' }}
          >
            <div className="flex flex-row items-center justify-between">
              <div
                className="rounded-12 hover py-16px text-button-s font-semibold text-textbox bg-primary w-full text-center"
                onClick={this.showFinalConfirmation}
              >
                Submit Details
              </div>
            </div>
          </div>
        )}
        {this.state.purchaseSuccess && (
          <PaymentSuccessModal hideModal={this.hideModal} />
        )}
        {this.state.submitConfirm && <SubmitConfirmModal />}
        {this.state.showAddMemberModal && (
          <AddMemberModal
            showModal={this.state.showAddMemberModal}
            hideModal={this.hideModal}
            relations={coveredRelations}
            addRelative={this.addRelative}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
  user: state.user,
});

export default connect(mapStateToProps)(CovidOrderSummary);
