import React, { Component } from 'react';
import styled from 'styled-components';
import uploadIcon from '../../images/common/upload.svg';
import fileIcon from '../../images/star-health-status/file-icon.svg';
import photoIcon from '../../images/star-health-status/photo-icon.svg';

const ModalBoundary = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background: #ffffff;
  border-radius: 8px;
`;

const Title = styled.div`
  font-weight: 600;
  color: #ffffff;
`;

const InnerBoundary = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #3a5c81;
  border-radius: 8px 8px 0px 0px;
  padding: 18px;
`;

const UploadButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 95%;
  padding: 24px;
  border-radius: 8px;
`;

const Content = styled.label`
  color: black;
  font-size: 12px;
  padding-top: 2px;
  text-align: center;
`;

export default class WellnessUploadModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadedFile: null,
      validSize: false,
    };
  }

  uploadFileAndCheck = (file) => {
    console.log(file);
    const size = file.size / (1024 * 1000);
    console.log(size);
    if (size >= 5) {
      console.log('File is too large');
    } else {
      this.setState({ uploadedFile: file, validSize: true });
      this.props.setValidSizeAndFile(true, file);
    }
  };

  render() {
    return (
      <ModalBoundary>
        <HeaderContainer>
          <div
            className="p-16px pl-20px 
              border-txtsecondary-shades4 flex flex-row items-center
              "
          >
            <img src={uploadIcon} alt={'upload'} height={20} width={17} />
            <p
              className="text-title-m font-semibold pl-12px"
              style={{ color: '#ffffff' }}
            >
              {this.props.detailedText}
            </p>
          </div>
        </HeaderContainer>
        <InnerBoundary>
          <UploadButton>
            <input
              type="file"
              hidden
              id="uploadImg"
              accept=".png, .jpeg, jpg"
              onChange={(e) => this.uploadFileAndCheck(e.target.files[0])}
            />
            <Content for="uploadImg">
              <img src={photoIcon} alt="" />
            </Content>
            <Content for="uploadImg">IMAGE</Content>
          </UploadButton>
          <UploadButton>
            <input
              type="file"
              hidden
              id="uploadDoc"
              accept="application/pdf"
              onChange={(e) => this.uploadFileAndCheck(e.target.files[0])}
            />
            <Content for="uploadDoc">
              <img src={fileIcon} alt="" />
            </Content>
            <Content for="uploadDoc">PDF</Content>
          </UploadButton>
        </InnerBoundary>
      </ModalBoundary>
    );
  }
}
