import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from './Text';

const OuterContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  justify-content: center;
  position: relative;
  background: rgba(136, 82, 204, 0.3);
  border-radius: ${(props) => `${props.borderRadius}rem`};
  margin: 0.5rem 0;
`;

const ProgressContainer = styled.div`
  position: absolute;
  left: 0;
  background: #916ef7;
  height: 100%;
  width: ${(props) => props.uploadProgress};
  border-radius: ${(props) => `${props.borderRadius}rem`};
`;

const LoadingText = styled(Text)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: ${() => `${0.625 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
  z-index: 10;
`;

const UploadingComponent = ({ className, borderRadius, uploadProgress }) => (
  <OuterContainer className={className} borderRadius={borderRadius}>
    <LoadingText uploadProgress={uploadProgress}>Uploading...</LoadingText>
    <ProgressContainer
      uploadProgress={uploadProgress}
      borderRadius={borderRadius}
    />
  </OuterContainer>
);

UploadingComponent.propTypes = {
  uploadProgress: PropTypes.string,
  borderRadius: PropTypes.number,
};

UploadingComponent.defaultProps = {
  uploadProgress: '1%',
  borderRadius: 2,
};

export default UploadingComponent;
