import React, { Component } from 'react';
import styled from 'styled-components';
import { Text } from '../../components/common/Text';
import tickIcon from '../../images/lab-test/tick.png';
import Tappable from 'react-tappable/lib/Tappable';
import { FaArrowLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import LoadingComponent from '../../components/common/LoadingComponent';
import axios from 'axios';
import config from '../../config';
import { showError } from '../../utils';
import pdfIcon from '../../images/lab-test/pdf-icon.svg';
import { getCompletedActivityStatus } from '../../services/star-health';
import Tick from '../../images/star-health-status/Tick.svg';
import Circle from '../../images/star-health-status/Circle.svg';
import Line from '../../images/star-health-status/Line.svg';
import Dline from '../../images/star-health-status/Dline.svg';
import Cross from '../../images/star-health-status/cross.svg';
import HelpIcon from '../../images/star-health-status/help.svg';

const OuterContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  background: #f8f8f8;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: 100vh;
  position: relative;
`;

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 0.5rem;
  z-index: 10;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 6px 6px;
`;

const HeaderTitleContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const HeaderText = styled(Text)`
  margin-right: 0.625rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  padding-bottom: 0.5rem;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 16px;
`;

const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 4px;
  flex-shrink: 0;
  margin-bottom: 1rem;
  padding-left: 14px;
`;

const StepContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border-left: 1px solid;
  opacity: ${(props) => (props.inactive ? 0.3 : 1)};
`;

const StatusIcon = styled.div`
  border-radius: 50%;
  margin-left: -7px;
  background-color: ${(props) =>
    props.active || props.completed ? '#6544C6' : '#ffffff'};
  border: 1px solid #6544c6;
  width: 16px;
  height: 14px;
`;

const StatusIconImg = styled.img`
  background: #6544c6;
  margin-left: -8px;
  margin-top: -53px;
  border-radius: 50%;
  padding: 4px;
`;

const StepHeader = styled.div`
  width: 100%;
  color: #413958;
  font-weight: 600;
  font-size: 14px;
  background: #ffffff;
  margin-left: 3px;
  margin-bottom: 4px;
  padding-bottom: 6px;
  border-bottom: 1px solid #f0f3f6;
`;
const StepTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  background: rgb(79, 52, 210, 0.05);
  border-radius: 8px;
  padding: 13px 15px;
  font-weight: 500;
  margin-top: -10px;
  width: 100%;
`;

const StepDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 10px 15px;
  padding-top: 7px;
  margin: 9px;
  width: 100%;
  border: ${(props) =>
    props.active || props.completed ? '1px solid #714fff' : ''};
`;

const RewardDataContainer = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #e3e3e3;
`;

const TableDiv = styled.div`
  width: 100%;
  padding: 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const LeftElement = styled.p`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #404040;
`;

const RightElement = styled.p`
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  color: #404040;
  word-wrap: break-word;
  text-align: center;
  padding-left: 3px;
`;

const CorporateImage = styled.img`
  width: 68px;
  height: 9.76px;
  display: inline-block;
  float: right;
  margin-top: 15px;
  margin-right: 16px;
`;

const ExtraInfo = styled.div`
  width: 100%;
  min-width: 326px;
  background: #ffffff;
  margin-top: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding-bottom: 16px;
  margin-bottom: 16px;
`;

const TableDiv1 = styled.div`
  width: 100%;
  height: 36px;
  margin-bottom: 16px;
`;

const ExtraData = styled.div`
  width: 100%;
  float: left;
  margin-top: 14px;
  font-family: Manrope;
  font-style: normal;
  font-weight: 600;
  font-size: 17px;
  line-height: 15px;
  display: flex;
  align-items: center;
  color: #21243c;
  margin-left: 18px;
  margin-bottom: 20px;
  color: #404040;
`;

const Image = styled.img`
  width: 67px;
  height: 86px;
  min-width: 67px;
  min-height: 86px;
  margin-left: 14px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  align-items: center;
  font-weight: 600;
  border-radius: 8px;
  border: 1px solid #013adeb3;
  margin-bottom: 6px;
`;

const PdfUpload = styled.img`
  width: 67px;
  height: 86px;
  display: inline;
  margin-left: 14px;
  display: flex;
  justify-content: center;
  color: #c94c4c;
  background: #ffffff;
  align-items: center;
  font-weight: 600;
  border-radius: 8px;
  border: 1px dashed #013adeb3;
`;

const UploadedImageLabel = styled.label`
  width: 55px;
  height: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #47464a;
  margin-bottom: 18px;
  margin-left: 5%;
`;

const Document = styled.div`
  width: 100%;
  min-width: 300px;
  padding-bottom: 13px;
  margin: 15px 0px 0px 0px;
  border-radius: 8px;
  border: 1px solid #e3e3e3;
`;

const DocumentHeading = styled.div`
  display: flex;
  width: 100%;
  min-width: 120px;
  height: 21px;
  font-family: 'Manrope', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 15px;
  color: #333333;
  border-radius: 8px;
  margin-top: 13px;
  padding-left: 6px;
  margin-left: 9px;
  margin-bottom: 15px;
`;

const Button = styled.button`
  width: 90%;
  border-bottom: 14px;
  padding: 10px;
  background-color: #916ef7;
  color: #ffffff;
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const BackContainer = styled(Tappable)`
  margin-right: 1.125rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  &.Tappable-active {
    opacity: 0.2;
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
`;

const Tracker = styled.div`
  display: flex;
  width: 10%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StatusDetails = styled.div`
  display: flex;
  width: 90%;
  margin-left: 2%;
  flex-direction: column;
  opacity: ${(props) => (props.active ? 1 : 0.3)};
  // border: ${(props) => (props.border ? '1px solid blue' : '')};
`;

const DateContainer = styled.div`
  width: 100%;
  text-align: right;
  font-size: 12px;
  margin-top: 3px;
`;

const StyledArrow = styled(FaArrowLeft)`
  font-size: ${() => `${1 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const RejectedConatiner = styled.div`
  width: 100%;
  background: rgba(242, 85, 64, 0.1);
  border-radius: 12px;
  padding: 16px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
`;

const SelectButton = styled.button`
  margin-top: 14px;
  border-radius: 8px;
  border-bottom: 14px;
  padding: 12px;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  background: #013adeb3;
  color: white;
  font-size: 16px;
  font-weight: 600;
`;

class WellnessRewardStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      policyId: null,
      rewardReqId: null,
      memberId: null,
      authToken: null,
      data: null,
      timeLine: [],
      showTimeLine: true,
      from: '',
    };

    const { location } = this.props;
    if (location && location.state) {
      console.log(location.state);
      this.state.policyId = location.state.policyId;
      this.state.rewardReqId = location.state.rewardId;
      this.state.authToken = location.state.authToken;
      this.state.memberId = location.state.memberId;
      if (location.state.from) {
        this.state.from = location.state.from;
      }
    }
  }

  componentDidMount() {
    this.getCompletedActivityStatus();
  }

  raiseTicket = () => {
    window.open(
      `mailto:support@starhealth.in?subject=Issue in Reward Activity&body=`
    );
  };

  getCompletedActivityStatus = () => {
    getCompletedActivityStatus(this.state.authToken, this.state.rewardReqId)
      .then((response) => {
        console.log(response.result);
        this.setState({
          data: response.result,
          timeLine: response.timeline,
          showTimeLine: response.showTimeline,
          loading: false,
        });
      })
      .catch((err) => console.log(err));
  };

  render() {
    const { data, timeLine, showTimeLine } = this.state;
    const remove = timeLine.length - 1;
    const stepsRender = (ele, index) => {
      const setStyle = index === remove;
      return (
        <>
          <StatusContainer
            active={ele.active}
            style={{ fontWeight: '600', fontSize: '16px' }}
          >
            <Tracker
              style={{ justifyContent: setStyle ? 'flex-start' : 'center' }}
            >
              <div>
                {ele.dateTime || ele.active ? (
                  <img src={ele.name === 'rejected' ? '' : Tick} />
                ) : (
                  <img src={Circle} />
                )}
              </div>
              {!setStyle ? (
                <div>
                  {ele.dateTime || ele.active ? (
                    <img src={Line} />
                  ) : (
                    <img src={Dline} />
                  )}
                </div>
              ) : null}
            </Tracker>
            <StatusDetails
              active={ele.dateTime || ele.active}
              border={ele.active}
            >
              <StepTextContainer>
                <div className="txt-black-500-11">{ele.name}</div>
              </StepTextContainer>
              <DateContainer>{ele.dateTime}</DateContainer>
            </StatusDetails>
          </StatusContainer>
        </>
      );
    };

    return (
      <div className="overflow-auto w-full">
        {this.state.loading ? (
          <div className="p-20 center">
            <LoadingComponent />
          </div>
        ) : (
          <OuterContainer>
            <HeaderContainer>
              <BackContainer
                onTap={() => {
                  if (this.state.from === 'star-home') {
                    this.props.history.goBack();
                  } else {
                    this.props.history.push('/home/wellness-rewards', {
                      currentState: 'Completed',
                      policyId: this.state.policyId,
                      memberId: this.state.memberId
                    });
                  }
                }}
              >
                <StyledArrow />
              </BackContainer>
              <HeaderTitleContainer>
                <HeaderText>Reward Status</HeaderText>
              </HeaderTitleContainer>
              <a href="mailto:support@starhealth.in?subject=Issue in Reward Activity&body=">
                <div
                  className="flex flex-row justify-between"
                  onClick={this.raiseTicket}
                >
                  <img src={HelpIcon} style={{ marginRight: '4px' }} />
                  <p
                    style={{
                      fontSize: '13px',
                      fontWeight: '600',
                      color: '#3F8CFF',
                    }}
                  >
                    Need Help ?
                  </p>
                </div>
              </a>
            </HeaderContainer>
            <BodyContainer>
              {!showTimeLine && (
                <RejectedConatiner>
                  <div
                    className="flex flex-row"
                    style={{
                      fontWeight: '600',
                      fontSize: '16px',
                      alignItems: 'flex-start',
                    }}
                  >
                    <img src={Cross} width={'28px'} className="mr-8px" />
                    <div>
                      <div>Reward Rejected</div>
                      <LeftElement>{data.rejectionReason}</LeftElement>
                    </div>
                  </div>
                </RejectedConatiner>
              )}
              <RewardDataContainer>
                <DocumentHeading>Reward Details</DocumentHeading>
                <TableDiv>
                  <LeftElement>Policy</LeftElement>
                  <RightElement>{data.policyName}</RightElement>
                </TableDiv>
                <TableDiv>
                  <LeftElement>Activity</LeftElement>
                  <RightElement>{data.rewardName}</RightElement>
                </TableDiv>
                {/* <TableDiv>
                  <LeftElement>Type</LeftElement>
                  <RightElement>{data.docType}</RightElement>
                </TableDiv>
                <TableDiv>
                  <LeftElement>Related</LeftElement>
                  <RightElement>{data.docRelatedTo}</RightElement>
                </TableDiv>
                <TableDiv>
                  <LeftElement>Belongs To</LeftElement>
                  <RightElement>{data.docBelongingTo}</RightElement>
                </TableDiv>
                <TableDiv>
                  <LeftElement>Named</LeftElement>
                  <RightElement>
                    {data.docName != 'null' ? data.docName : 'NA'}
                  </RightElement>
                </TableDiv> */}
                <TableDiv>
                  <LeftElement>Description</LeftElement>
                  <RightElement>
                    {data.docDesc != null ? data.docDesc : 'NA'}
                  </RightElement>
                </TableDiv>
              </RewardDataContainer>
              {showTimeLine && (
                <Document
                  style={{
                    backgroundColor: '#ffffff',
                  }}
                >
                  <DocumentHeading>Reward status</DocumentHeading>
                  <StepsContainer>{timeLine.map(stepsRender)}</StepsContainer>
                </Document>
              )}
              {data.fileUrl && (
                <Document
                  style={{
                    backgroundColor: '#ffffff',
                  }}
                >
                  <DocumentHeading>Uploaded Document</DocumentHeading>
                  <div
                    className="cursor-pointer"
                    onClick={() => window.open(data.fileUrl, '_blank')}
                  >
                    {data.fileUrl.substring(data.fileUrl.length - 3) ===
                      'png' ||
                    data.fileUrl.substring(data.fileUrl.length - 3) ===
                      'jpg' ? (
                      <Image src={data.fileUrl} />
                    ) : (
                      <PdfUpload src={pdfIcon} />
                    )}
                    <UploadedImageLabel>
                      {data.fileUrl.substring(data.fileUrl.length - 9)}
                    </UploadedImageLabel>
                  </div>
                </Document>
              )}
              <div className="absoulte bottom-1">
                <SelectButton onClick={() => this.props.history.push('/home')}>
                  Home
                </SelectButton>
              </div>
            </BodyContainer>
          </OuterContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  authToken: state.user.authToken,
});

export default connect(mapStateToProps)(WellnessRewardStatus);
