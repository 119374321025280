import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import TappableCard from '../common/TappableCard';
import { EarnBadge } from '../common/Fitcoin';
import { Text, BoldText } from '../common/Text';

const OuterContainer = styled(TappableCard)`
  padding: 0.375rem 0.5625rem;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: flex-start;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

const TitleText = styled(Text)`
  font-size: 15px;
  color: #050505;
  margin-left: 1rem;
`;

const BadgeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.25rem;
`;

const StyledEarnBadge = styled(EarnBadge)`
  margin: 0.125rem;
`;

const DiscountBadge = styled.div`
  background-image: linear-gradient(to left, #ffa14f, #db4c6d);
  border-radius: 3rem;
  padding: 0.125rem 0.5625rem;
  display: flex;
  align-items: center;
  float: left;
  margin: 0.125rem;
`;

const DiscountBadgeText = styled(BoldText)`
  color: white;
  font-size: ${() => `${0.6875 * process.env.REACT_APP_FONT_MULTIPLIER}rem`};
`;

const DashboardActionCard = ({
  className,
  onTap,
  title,
  icon,
  discountText,
  earnBadge,
}) => (
  <OuterContainer className={className} onTap={onTap}>
    <TitleContainer>
      <img src={icon} alt="Health query" />
      <TitleText>{title}</TitleText>
    </TitleContainer>
    <BadgeContainer>
      {discountText && (
        <DiscountBadge>
          <DiscountBadgeText>{discountText}</DiscountBadgeText>
        </DiscountBadge>
      )}
      {earnBadge && <StyledEarnBadge />}
    </BadgeContainer>
  </OuterContainer>
);

DashboardActionCard.propTypes = {
  onTap: PropTypes.func,
  discountText: PropTypes.string,
  earnBadge: PropTypes.bool,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
};

DashboardActionCard.defaultProps = {
  discountText: null,
  earnBadge: false,
};

export default DashboardActionCard;
