import React, { Component } from 'react';
import styled from 'styled-components';
import { fontSize } from '../../utils';
import StyledTappable from '../common/StyledTappable';

import { Text } from '../common/Text';

const OuterContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const OptionContainer = styled(StyledTappable)`
  font-size: ${fontSize(1)};
  min-height: 2rem;
  padding: 0.5rem 0;
  text-align: center;
  color: #916ef7;
  border-bottom: 1px solid #dadada;
`;

const OptionText = styled(Text)`
  font-size: ${fontSize(1)};
  color: #916ef7;
`;

class SingleListInput extends Component {
  onOptionTapped(option) {
    if (option.callToAction && option.callToAction.action === 'openVertical') {
      this.props.showDoctors();
    }
    const { sendMessage } = this.props;
    const msg = {
      text: option.name,
      cardType: 'text',
      flowDirective: {
        evidences: [
          {
            evidenceId: option.evidenceId,
            choiceId: option.choiceId,
          },
        ],
      },
    };
    sendMessage(msg);
  }

  render() {
    const { uploadAttachment } = this.props;
    const renderListOptions = (ele, index, array) => {
      return ele.callToAction && ele.callToAction.action.match(/Upload/) ? (
        <OptionContainer
          key={ele.evidenceId + ele.choiceId + index}
          onTap={() =>
            document.querySelector(`#${ele.callToAction.action}`).click()
          }
        >
          <input
            type="file"
            id={ele.callToAction.action}
            accept="image/*,application/pdf,.pdf"
            style={{ display: 'none' }}
            onChange={() => uploadAttachment(ele)}
          />
          <OptionText>{ele.name}</OptionText>
        </OptionContainer>
      ) : (
        <OptionContainer
          key={ele.evidenceId + ele.choiceId + index}
          onTap={() => this.onOptionTapped(ele)}
        >
          <OptionText>{ele.name}</OptionText>
        </OptionContainer>
      );
    };

    return (
      <OuterContainer>
        {this.props.options.map(renderListOptions)}
      </OuterContainer>
    );
  }
}

export default SingleListInput;
